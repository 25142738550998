import React, { useState } from "react";
import { constore } from "../utils/mixins/mixin";
export const Pragraph = (props)=>{
  let [readMore, setReadMore] = useState(false);
  let content = props.content || props.children;
  let parr = content.split('\n').filter(v => v);
  const isMobile = document.body.clientWidth <= 900;
  return <>
      {
          parr.map((v, i, arr) => {
              if (isMobile && i == 0 && arr.length>1) {
                  if (!readMore) {
                      return <p>
                          {v}
                          <span className={`ml-auto mob-view ${isMobile && !readMore ? 'show-read-more' : 'hide-read-more'}`} onClick={() => setReadMore(true)}>
                              Read more..
                          </span>
                      </p>
                  } else {
                      return <p>{v}</p>;
                  }
              } else if (!isMobile || readMore || arr.length==1) {
                  return <p>{v}</p>
              }
          })
      }
  </>
}

const add2Cart = (e, pack, props) => {
  props.services.apiCurd("Frontend", {
    action: "add2cart",
    service_package_id: pack.id,
    service_id: pack.service_id,
    browser_fingerprint: props.services.getBrowserFingerprint().hash()
  }).then(({ data }) => {
    props.redirect("/order-summary");
  });
}

export default constore(function (props) {

  const getService = (name) => props.getProps('data.service.' + name);
  const packcolor = {
    "basic": "rgb(81, 150, 21)",
    "pro": "rgb(33, 138, 145)",
    "premium": "rgb(115, 38, 81)",
  };
  const responsiveImage = {
    width: '100%',
    height: 'auto',
    maxWidth: '100%',
  };
  const isMobile = document.body.clientWidth <= 900;
  const content =  props.getcontent('home.about');
  const countryName = props.getProps('country.name');
  const inCountry = "in "+countryName;
  return (
    <>
      <section id="home">
        <div className="maximage-slider responsive-banner ">
          <div className="divider parallax">
            <img
               className='img-fluid'
              src={"/files/secure/360.jpg?fileId=" + getService('banner_image') + "&rd=16&asimg=1" + ("&mobile=" + (isMobile ? '1' : '0'))}  
             
              style={{
                backgroundPosition: 'center',
                ...responsiveImage,
              }}
              alt="Banner"
            />
          </div>
        </div>
        <div className="display-table">
          <div className="display-table-cell">
            <div className="slider-size">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="pt-0 pb-350"></div>
                </div>
              </div>
            </div>
            <div className="border-1px" style={{ backgroundColor: '#C0C0C0' }}>
              <p className="pt-5 text-theme-color-2 text-center font-25" style={{ fontFamily: 'Geneva, Verdana, sans-serif',fontWeight: 'bold', }}>
                {getService('title').split(inCountry).join("")}
                {content?.titles1}
              </p>
            </div>
          </div>
        </div>
      </section>
 
      <section>
      <div className="container pt-50">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12">
              <div className="meet-doctors">
                <h1 className="mt-0 font-26 text-center pb-20">
                  <span className="text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getService('title')}</span>
                </h1>
                <div style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px' }}>
                  <Pragraph content={getService('description')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>

      <section id ="ourr" className="container">
        <div className="row">
          <div className="col-sm-12 blog-pull-right mob-hide">
            <div className="row">
              <h2 className="mt-0 line-height-1 font-26 text-center pt-50 pb-50">
                <span className="text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}> Our {getService('title')}</span>
              </h2>
              {(getService('feature_list') || []).filter(o => !o.excluded).chunk(3).map(row => (
                <div key={row[0].id} className="row" style={{ justifyContent: 'center' }}>
                  {row.map(feature => (
                    <div key={feature.id} className="col-md-4 pb-20">
                      <div className="service-block bg-white">
                        <div className="d-flex flex-column justify-content-between align-items-center">
                          <img
                            alt="featured-project"
                            className="rounded img-fluid"
                            src={"/files/secure/colorcubz.jpg?fileId=" + (feature.image || 1) + "&rd=41&asimg=1"}
                            style={{
                              height: '40%',
                              width: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center',
                              background: 'black',
                            }}
                          />
                          <div className="p-3 text-center">
                            <p className="font-13 text-uppercase pt-10 pb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'left', paddingLeft: "10px" }}>
                              <strong>{feature.title}</strong>
                            </p>
                            <p className="font-13" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'left', fontSize: '16px', paddingLeft: "12px" }}>{feature.desc}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lighter">
        <h3 className="text-center city-head-font pb-30 pt-50 font-26 text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}> Our Pricing Packages for {getService('title')} </h3>
        <div className="section-content">
          <div className="container">
            <div className="row">
              {(getService('service_packages') || []).map((pack) => (
                <div key={pack.id} className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                  <div className="pricing-table">
                    <div className="font-16 pl-20 text-center pr-20 p-10 btn-theme-colored" style={{ backgroundColor: packcolor[pack.name] }}>
                      <strong className="text-white font-20 text-center" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{pack.title}</strong>
                    </div>
                    <img
                      src={`/files/secure/colorcubz.jpg?fileId=${pack.image}&rd=41&asimg=1`}
                      alt="service_pack"
                      style={{
                        height: '150px',
                        width: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        background: 'black',
                      }}
                    />
                    <div className="bg-white border-1px p-30 pt-20 pb-30">
                      <p className="font-28 m-0 text-center text-black">
                        <span className="text-theme-colored">$ </span>{pack.price || 1}</p>
                      <p className="text-center font-18" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getService('name').includes("360-degree-panorama-stitching-services") ? "Per Panorama" : "Per Image"}</p>
                      {/* <p className="text-center font-18" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>Per Image</p> */}
                      <ul className="list check theme-colored pb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', fontSize: '14px' }}>
                        {pack.includes.split(',').map((value, index) => (
                          <li key={index}>
                            <label title={value}>{value}</label>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <button onClick={(e) => add2Cart(e, pack, props)} className="btn btn-lg btn-theme-color-2  btn-block pt-15 pb-15 btn-flat">
                      <span className="btn btn-colored btn-theme-colored text-white" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>Buy Now</span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}, "AppServiceDetails");
