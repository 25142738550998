import api from "./apisetup";
import apibase from "./api.base";
const files = import.meta.glob('../../pages/**/*.api.js', { eager: true });
const fileskeys = Object.keys(files);
export const entries = {
    ...fileskeys.map(key=>files[key].default).reduce((o,n)=>({...o,...n}),{})
}
const apis = {
    ...apibase,
    ...entries
}
window.api = api;
window.apis = apis;
export default apis;