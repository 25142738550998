import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppLatestNews from "../../components/AppLatestNews";

export default constore(function () {
    return (
        <>
            <AppLatestNews/>
            <AppFooter/>
        </>
    )
}, "latestnews");