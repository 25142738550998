import React from 'react';
import { constore } from '../../utils/mixins/mixin';

const AppPricingDetailsMain = constore(function (props) {
  const content =  props.getcontent('home.about'); 
  return (
    <section id="pricing" className="bg-lighter">
      <div className="container">
        <h2 className="title text-center text-bold mb-10 pb-20 font-28 mt-0 line-height-1 text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}> {content?.titles}</h2>
        <div className="section-content">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
              <div className="pricing-table maxwidth400">
                <div className="font-18 pl-20 text-center text-white pr-20 p-10 text-uppercase" style={{ backgroundColor: 'rgb(81, 150, 21)', fontWeight: 700 }}>Basic<span className="font-15 pull-right mt-15 text-white"></span></div>
                <img
                  src="/files/secure/basic-photo-editing-pricing-package.jpg?fileId=174&rd=94&asimg=1"
                  alt="DAY TO NIGHT CONVERSION"
                  style={{
                    height: '150px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    background: 'black',
                  }}
                />
                <div className="bg-white border-1px p-30 pt-20 pb-20">
                  <p className="font-24 text-center" style={{ color: 'rgb(153, 153, 153)', fontFamily: 'Geneva, Verdana, sans-serif' }}>
                    From<span className="text-theme-colored"> $0.3 </span>
                  </p>
                  <ul className="list check theme-colored pb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', lineHeight: '1.5', padding: '15px', fontSize: '14px', minHeight: '300px' }}>
                  <li><a href={props.buildpath("/product-photo-editing-services")}>Product Photo Editing Services</a></li>
                    <li><a href={props.buildpath("/portrait-retouching-services")}>Portrait Retouching Services</a></li>
                    <li><a href={props.buildpath("/image-manipulation-services")}>Image Manipulation Services</a></li>
                    <li><a href={props.buildpath("/real-estate-photo-editing-services")}>Real Estate Editing Photo Services</a></li>
                    <li><a href={props.buildpath("/image-clipping-services")}>Image Clipping Services</a></li>
                    <li><a href={props.buildpath("/photo-restoration-services")}>Photo Restoration Services</a></li>
                  </ul>
                  <a href={props.buildpath("/pricing")} className="btn btn-lg text-uppercase btn-block pt-20 pb-20 btn-flat text-white" style={{ backgroundColor: 'rgb(81, 150, 21)', color: 'rgb(51, 51, 51)', fontWeight: 600, fontSize: 16 }}>
                     View Details
                  </a>
                </div>
             
              
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
              <div className="pricing-table maxwidth400">
                <div className="font-18 pl-20 text-white text-center pr-20 p-10 text-uppercase" style={{ backgroundColor: 'rgb(33, 138, 145)', fontWeight: 700 }}>Pro <span className="font-15 pull-right mt-15 text-white"></span></div>
                <img
                  src="/files/secure/pro-photo-editing-pricing-package.jpg?fileId=173&rd=21&asimg=1"
                  alt="PHOTO EDITING"
                  style={{
                    height: '150px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    background: 'black',
                  }}
                />
                <div className="bg-white border-1px p-30 pt-20 pb-20">
                  <p className="font-24 text-center" style={{ color: 'rgb(153, 153, 153)', fontFamily: 'Geneva, Verdana, sans-serif' }}>
                    From<span className="text-theme-colored"> $1 </span>
                  </p>
                  <ul className="list check theme-colored pb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', lineHeight: '1.5', padding: '15px', fontSize: '14px', minHeight: '300px' }}>
                  <li><a href={props.buildpath("/product-photo-editing-services")}>Product Photo Editing Services</a></li>
                    <li><a href={props.buildpath("/portrait-retouching-services")}>Portrait Retouching Services</a></li>
                    <li><a href={props.buildpath("/image-manipulation-services")}>Image Manipulation Services</a></li>
                    <li><a href={props.buildpath("/real-estate-photo-editing-services")}>Real Estate Editing Photo Services</a></li>
                    <li><a href={props.buildpath("/image-clipping-services")}>Image Clipping Services</a></li>
                    <li><a href={props.buildpath("/photo-restoration-services")}>Photo Restoration Services</a></li>

                  </ul>
                  <a href={props.buildpath("/pricing")} className="btn btn-lg text-uppercase btn-block pt-20 pb-20 btn-flat text-white" style={{ backgroundColor: 'rgb(33, 138, 145)', color: 'rgb(51, 51, 51)', fontWeight: 600, fontSize: 16 }}>
                  View Details
                  </a>
                </div>
               
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
              <div className="pricing-table maxwidth400">
                <div className="font-18 pl-20 text-white text-center pr-20 p-10 text-uppercase" style={{ backgroundColor: 'rgb(115, 38, 81)', fontWeight: 700 }}>Premium<span className="font-15 pull-right mt-15 text-white"></span></div>
                <img
                  src="/files/secure/premium-photo-editing-pricing-package.jpg?fileId=172&rd=56&asimg=1"
                  alt="RETOUCHING"
                  style={{
                    height: '150px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    background: 'black',
                  }}
                />
                <div className="bg-white border-1px p-30 pt-20 pb-20">
                  <p className="font-24 text-center" style={{ color: 'rgb(153, 153, 153)', fontFamily: 'Geneva, Verdana, sans-serif' }}>
                    From<span className="text-theme-colored"> $3 </span>
                  </p>
                  <ul className="list check theme-colored pb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', lineHeight: '1.5', padding: '15px', fontSize: '14px', minHeight: '300px' }}>
                    <li><a href={props.buildpath("/360-degree-panorama-stitching-services")}>360 Degree Photo Editing Services</a></li>
                    <li><a href={props.buildpath("/product-photo-editing-services")}>Product Photo Editing Services</a></li>
                    <li><a href={props.buildpath("/portrait-retouching-services")}>Portrait Retouching Services</a></li>
                    <li><a href={props.buildpath("/real-estate-floor-plan-creation-services")}>Real Estate Floor Plan Services</a></li>
                    <li><a href={props.buildpath("/image-manipulation-services")}>Image Manipulation Services</a></li>
                    <li><a href={props.buildpath("/real-estate-photo-editing-services")}>Real Estate Editing Photo Services</a></li>
                    <li><a href={props.buildpath("/image-clipping-services")}>Image Clipping Services</a></li>
                    <li><a href={props.buildpath("/photo-restoration-services")}>Photo Restoration Services</a></li>
                  </ul>
                  <a href={props.buildpath("/pricing")} className="btn btn-lg text-uppercase btn-block pt-20 pb-20 btn-flat text-white" style={{ backgroundColor: 'rgb(115, 38, 81)', color: 'rgb(51, 51, 51)', fontWeight: 600, fontSize: 16 }}>
                  View Details
                  </a>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}, "AppPricingDetailsMain");

export default AppPricingDetailsMain;
