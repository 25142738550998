
import React, { useState, useEffect } from 'react';



const AppCartPopUp = () => {
    const [cartVisible, setCartVisible] = useState(false);
  
    const toggleCart = () => {
      setCartVisible(!cartVisible);
    };
  
    useEffect(() => {
      const handleCartClick = () => {
        toggleCart();
      };
  
      const cartButton = document.getElementById("cart");
      cartButton.addEventListener("click", handleCartClick);
  
      return () => {
        cartButton.removeEventListener("click", handleCartClick);
      };
    }, []);
  
    return (
      <div>
        <nav>
          <div className="container">
            <ul className="navbar-right">
              <li>
                <a href="" id="cart">
                  <i className="fa fa-shopping-cart"></i>
                  <span className="badge">3</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
  
        <div className="container">
          <div className={`shopping-cart ${cartVisible ? 'visible' : ''}`}>
            <div className="shopping-cart-header">
              <ul className="shopping-cart-items">
                <li className="clearfix">
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/cart-item1.jpg" alt="item1" />
                  <span className="item-name">Sony DSC-RX100M III</span>
                  <span className="item-price">RS:849.99</span>
                  <span className="item-quantity">Quantity: 01</span>
                </li>
              </ul>
              <a href="" className="button">
                Checkout
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default AppCartPopUp;
    

