import { constore } from "../../utils/mixins/mixin";

export default constore(function () {
    return (
        <div className="panel">
            <div className="panel-title"> <a className="collapsed" data-parent="#accordion1" data-toggle="collapse" href="#accordion12" aria-expanded="false"> <span className="open-sub"></span> LOGIN DETAILS</a> </div>
            <div id="accordion12" className="panel-collapse collapse" aria-expanded="false" style={{ height: "0px;" }}>
                <div className="panel-content">

                    <form id="reservation_form" name="reservation_form" className="reservation-form file-uploader" method="post" action="https://www.colourcubz.com/mailing" enctype="multipart/form-data">

                        <div className="row">
                            <input type="hidden" name="user_visited_url" value="https://www.colourcubz.com/order-summary" />
                            <input type="hidden" name="type" id="type" value="Enquiry Now Form" />
                            <div className="col-sm-12">
                                <div className="form-group  mb-10 ">
                                    <input placeholder="Enter Name" type="text" id="name" name="name" required="" value=""
                                        className="form-control mb-10 mt-10 himp-32" />
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group mb-10">
                                    <input placeholder="Email" type="text" id="email" name="email" className="form-control himp-32" required="" value="" />
                                </div>
                            </div>


                            <div className="col-sm-6">
                                <div className="form-group mb-10">
                                    <div className="styled-select">
                                        <input type="hidden" className="hidden_country" name="country" value="" />
                                        <input type="hidden" className="hidden_country_slug" name="country_slug" value="" />
                                        <a href="#" data-toggle="modal" data-target="#countries_model" className="form-control himp-32 country">
                                            <span className="span_country" data-toggle="tooltip" title="Choose Country">Choose Country</span>
                                            <i className="fa fa-caret-down pull-right"></i>
                                        </a>
                                    </div>            </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-10">
                                    <div className="styled-select">
                                        <input type="hidden" className="hidden_city" name="city" value="" />
                                        <a href="#" data-toggle="modal" data-target="#cities_model" className="form-control himp-32 city">
                                            <span className="span_city" data-toggle="tooltip" title="Choose City">Choose City</span>
                                            <i className="fa fa-caret-down pull-right"></i>
                                        </a>
                                    </div>            </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-10">
                                    <input placeholder="Phone" type="text" id="phone" name="phone" className="form-control himp-32" required="" value="" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-10 ">
                                    <div className="styled-select">
                                        <input type="hidden" className="hidden_course" name="courses" value="" />
                                        <input type="hidden" className="hidden_course_slug" name="courses_slug" value="" />
                                        <a href="#" data-toggle="modal" data-target="#courses_model" className="form-control himp-32 course"><span className="span_course" data-toggle="tooltip" title="Choose Course">Select Services</span><i className="fa fa-caret-down pull-right"></i></a>

                                    </div>          </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group mb-10 ">
                                    <input placeholder="Enter Company Name" type="text" id="company_name" name="company_name" required="" value="" className="form-control himp-32" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-10 ">
                                    <input placeholder="Enter your Company Website" type="text" id="job_title" name="job_title" required="" value="" className="form-control himp-32" />
                                </div>
                            </div>

                            <div className="col-md-12 pb-50 ">
                                <div className="form-group mb-10 ">
                                    <label className="text-white">Choose a file to Upload. Attach max 5 files (10MB each)</label>
                                    <div className="file-chooser">
                                        <input className="file-chooser__input form-control" type="file" name="file[]" id="real_file" data-maxfile="10240" multiple="" style={{ display: "none" }} />
                                        <span ><button type="button" id="custom_btn">Upload File</button>
                                            <span style={{ marginLeft: "2%", fontSize: "13px;" }} id="custom_text" className="text-white pb-10">If there are more than 5 you can send us via <b>Dropbox</b> <span style={{ fontSize: "13px;" }}>OR <b>Wetransfer</b> at <b>sales[at]colorscubz.com</b></span></span></span>
                                    </div>
                                    <div className="file-uploader__message-area text-white" style={{ color: "red;" }}>
                                        <p className="font-12 text-white"></p>
                                    </div>
                                </div>
                            </div>



                            <div className="col-sm-12">
                                <div className="form-group mb-10">
                                    <textarea placeholder="Enter Message" rows="4" className="form-control required" name="message" id="message" style={{ resize: "both" }}></textarea>
                                </div>
                            </div>
                            <div className="col-sm-6 pt-10">
                                <div className="mb-10 ml-20">
                                    <div className="g-recaptcha" data-sitekey="6LeqL-IUAAAAAKqfSXRM3YdIy0ibhGT91DxG23qZ"></div>
                                </div>

                            </div>
                        </div>



                        <div className="col-sm-12">
                            <div className="form-group mb-0 text-center">
                                <button type="submit" id="real_file" className="btn btn-colored btn-theme-color-2 text-white btn-lg enquire-now-button" data-loading-text="Please wait...">Submit Request</button>

                                <input type="hidden" name="_token" value="ulzIPblfRriWLeZe7X7P8OEYzi9AiUiSVx1l85gk" />
                            </div>
                        </div>

                    </form>





                </div>
            </div>
        </div>
    )
})