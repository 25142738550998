import { connect } from "react-redux";
import "../helpers/index";
import services from "../services";
import apis from "../api";
import _enum from "../enum";
import mokeresults from "../mokeresults";
import Emitter from "../emitters";
import { language } from "../languages/index";
import { styled } from "styled-components";

const emitObj = new Emitter();
window._enum = _enum;
window.mokeresults = mokeresults;
window.emit = emitObj;

console.log({services,apis});

const setInitials = () => {
    window._enum = {
        ..._enum,
        ...window.getpathkey(_enum)
    };
    window.mokeresults = {
        ...mokeresults,
        ...window.getpathkey(mokeresults)
    }
};

const parseurl = function (urlstr) {
    try {
        let str = urlstr.includes("http") ? urlstr : (window.location.origin + urlstr);
        let url = new URL(str);
        let splits = url.hash.split("?");
        if (splits.length === 2) {
            url.hash = splits[1];
        }
        url.search += splits[1] || '';
        url.params = Object.fromEntries(url.searchParams.entries());
        return url;
    } catch (e) {
        console.log("err", e);
        return urlstr;
    }
}

export const mixins = {
    authToken() {
        return app.props.$store?.authResponce?.access_token
    },
    params() {
        return parseurl(window?.location.toString()).params;
    },
    constore(component,name=null) {
        let component_name = name || component.name;
        component_name = component_name||Math.random().toString().slice(2);
        component_name += "Component";
        let myBind = {
            componentName: component_name,
            dump: { componentName: component_name },
            $emit: emitObj
        };
        myBind = window[component_name] = (window[component_name] || myBind);
        let WrappedComponent = component;
        return connect(
            (state) => {
                myBind.server = server.decode().parse();
                myBind.urlvars = (name="country",del='/')=>globalThis.$params[name]?del+globalThis.$params[name]:'';
                myBind.buildpath = (path,options={})=>{
                    let {hasCountry=true} = options;
                    let { pagename, country:countryobj={}, countries=[] } = myBind.server.props;
                    countries = countries.map(o=>o.code);
                    let { slug="",country="" } = globalThis?.$params||{};
                    let patharr = path.split("/");
                    let [ first,countryCode="",page="", pslug="" ] = patharr;
                    if(countries.includes(countryCode)) { country = countryCode;pslug=""; }
                    else { page=countryCode; }
                    if(!country && countryobj) { country = countryobj.code; }
                    if(!hasCountry) { country = ""; }
                    let fullpath = `/${country}/${page}/${pslug}`.split("//").filter(v=>v).join("/");
                    return (fullpath+location.href.url().search);
                };
                myBind.redirect = (path)=>{
                    location.href = myBind.buildpath(path);
                }
                myBind.search = (inc=['country'])=>"?"+Object.QueryString(Object.only(location.href.url().params,inc))
                myBind.$store = state.store;
                myBind.appstore = (key)=>key?Object.getNested(myBind.$store,key):myBind.$store;
                
                myBind.getcontent = (name,page=null,root=true)=>{
                    let content = myBind.server.props.contentdata||{};
                    page = root ? page :( page ?? myBind.server.page.name);
                    return name?Object.getNested(content,page?`${page}.${name}`:name):content;
                };
                myBind.getProps = (name,page=null)=>{
                    let content = myBind.server.props||{};
                    return name?Object.getNested(content,name):content;
                };
                return (
                    {
                        ...myBind,
                        $l: language(myBind.$store.language),
                        componentName: component_name,
                        $node: myBind,
                        $ns: myBind.dump,
                        $trigger:(key,...args)=>myBind.$emit.trigger(key,myBind.dump,...args),
                        $onTrigger:(key,callback)=>myBind.$emit.onTrigger(key,myBind.dump,callback),
                        $setNode: (obj,name=component_name) => {
                            obj = obj instanceof Function ? obj(myBind) : obj;
                            if(obj.props) {
                                let ele = document.querySelector(`#${obj.props.id}`);
                                ele && (ele.vnode = obj);
                                console.log({ele});
                                obj.props = Object.except(obj.props, ['$node', '$setNode','dump']);
                            }
                            if(name==component_name) {
                                Object.assign(window[name].dump, obj);
                            } else {
                                Object.assign(myBind.dump, obj);
                            }
                        },
                        watch: {}
                    }
                );
            },
            (dispatch) => {
                let ret = {};
                ret.dispatch = (payload) => {
                    try {
                        return dispatch({ type: 'store', payload });
                    } catch (error) {
                        dispatch({ type: 'store', payload });
                    }
                };
                ret.location = window?.location;
                ret.store = (name, value) => (name instanceof Array) ? ret.dispatch(name) : ret.dispatch({ name, value });
                ret.urlparams = parseurl(ret.location.href);
                ret.initialconstruct = mixins.initialconstruct;
                ret.navigate = (window?.navigate || (() => { }));
                ret.apis = Object.entries(apis).reduce((col, [key, value]) => ({ ...col, [key]: value.bind({ 
                    ...apis, 
                    props: Object.except(ret, ['apis'])
                }) }), {});
                ret.services = Object.entries(services).reduce((col, [key, value]) => ({ ...col, [key]: value.bind({ 
                    ...services,
                    apis:ret.apis,
                    endpoint:ret.apis.api(),
                    props: Object.except(ret, ['services']) 
                }) }), {});
                ret._enum = _enum;
                ret.moke = mokeresults;
                ret.$className = (...args) => Object.className(...args);
                myBind = window[component_name] || {};
                Object.assign(myBind,ret);
                return ret;
            }
        )(WrappedComponent);
    },
    initialconstruct(props = null, key = "") {
        let self = this || {};
        props = Object.assign({}, props || self.props);
        key = key || props.componentName;
        Object.entries(props.apis).reduce((collector, [key, value]) => {
            collector[key] = value.bind({
                ...this,
                ...props.apis
            });
            return collector;
        }, {});
        self.$store = props.$store;
        self.appstore = props.appstore;
        self.setstore = props.setstore;
        self.dispatch = props.dispatch;
        self.navigate = props.navigate;
        self.apis = props.apis;
        self.services = props.services;
        self._enum = props._enum;
        self.moke = props.moke;
        self.watch = mixins.watch.bind(self);
        window[key] = self;
    },
    watch() {

    },
    checkParent(ele, callback = (() => (false))) {
        let temp = ele;
        while (temp) {
            let flag = callback(temp, temp.parentElement);
            if (flag) {
                return temp.parentElement || flag;
            }
            temp = temp.parentElement;
        }
        return temp;
    },
    ...services,
    apis: apis,
    setInitials,
    _enum: () => (window._enum),
    $emit: emitObj
}
// window.onload = ()=>{
//     console.log("webpageloaded");
//     //setInitials();
// };


export const constore = mixins.constore;

export const createStyled = (stylcallback, componentcallback, name = Math.random().toString) => {
    const Component = stylcallback(styled);
    componentcallback.displayName = name;
    const RetComponent = componentcallback(Component);
    RetComponent.displayName = name;
    return RetComponent;
}

export default mixins;

window.constore = constore;