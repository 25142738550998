

Object.encodeuri = function (obj, prepend = "?") {
  const serialize = Object.entries(obj)
    .map((a) => a[0] + "=" + a[1])
    .join("&");
  return serialize ? `${prepend}${serialize}` : serialize;
};

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key], key, obj))
    .reduce((res, key) => { res[key] = obj[key]; return res; }, {});

Object.map = (obj, predicate) =>
  Object.keys(obj)
    .map(key => ({ key: key, value: predicate(obj[key], key, obj) }))
    .reduce((res, o) => { res[o.key] = o.value; return res; }, {});


Object.className = (obj) => {
  return Object.keys(Object.filter(obj, (v) => v)).join(" ");
};

Object.QueryString = (obj) => {
  let fobj = Object.filter(obj, (v) => ![undefined].includes(v));
  return Object.values(
    Object.map(fobj, (v, k) => `${k}=${encodeURIComponent(v)}`)
  ).join("&");
};

Object.deepClone = function (obj) {
  return JSON.stringify(obj).parse();
};

Object.attribute = (obj) => {
  return Object.entries(obj)
    .map(([key, val]) => `${key}='${val}'`)
    .join(" ");
};

Object.setValues = (obj, name, value) => {
  const setValues = (state, payload) => {
    let old = state;
    let key = "";
    let arr = payload.name.split(".");
    if (arr.length > 1) {
      let tmp = state;
      arr.forEach((k) => {
        old = tmp;
        tmp[k] = tmp[k] || {};
        tmp = tmp[k];
        key = k;
      });
      old[key] = payload.value;
    } else {
      key = payload.name;
      old = state[key];
      state[key] = payload.value;
    }
    return state;
  };
  if (typeof name == "string") {
    obj = setValues(obj, { name, value });
  } else {
    obj = setValues(obj, name);
  }
  return obj;
};

HTMLInputElement.prototype.setError = function (message) {
  let { badInput, patternMismatch, rangeOverflow,
    rangeUnderflow, stepMismatch, tooLong,
    tooShort, typeMismatch, valueMissing } = this.validity;
  let valid = !(badInput || rangeOverflow || patternMismatch || rangeUnderflow || stepMismatch);
  valid = valid && !(tooLong || tooShort || typeMismatch || valueMissing);
  (!valid) ? this.setCustomValidity(message) : this.setCustomValidity('');
}

HTMLInputElement.prototype.setMessage = function () {
  this.setError(this.validationMessage);
}

HTMLFormElement.prototype.getData = function () {
  let entries = new FormData(this).entries();
  let obj = {};
  for (let pair of entries) {
    let value = pair[1];
    let key = pair[0];
    let inputs = this.querySelectorAll(`[name='${key}']`);
    let input = inputs[0];
    let type = input.getAttribute('type');
    let parentElement = input.parentElement;
    if (parentElement.classList.contains('react-dropdown-select')) { type = 'select'; }
    let attributeValue = input.getAttribute('value');
    value = input.dataset.invalue || input.invalue || value;
    if (["checkbox"].includes(type)) {
      if (inputs.length > 1) {
        value = [...inputs].filter(v => v.checked).map(v => v.dataset.invalue || v.invalue || v.value);
        if (value.length && typeof value[0] == "string") {
          value = value.join(",");
        }
      } else {
        value = value === "on";
      }
    }
    if (["select", "select-one"].includes(type)) {
      value = ["All", "Select"].includes(attributeValue) ? "" : value;
    }
    obj[key] = value;
  }
  return obj;
};

window.genInput = function (obj) {
  let keys = Object.keyify(obj);
  return keys.reduce((collector, key) => {
    let ret = {
      ...collector,
      [key]: Object.getNested(obj, key)
    };
    if (!isNaN(key)) {
      return Object.values(ret);
    } else {
      return ret;
    }
  }, {})
}

window.parseInput = function (input) {
  let arrout = [];
  let output = {};
  Object.entries(structuredClone(input)).map(([key, value]) => {
    output = Object.setNested(key.match(/^\d.*/) ? arrout : output, key, value);
    arrout = output;
  });
  return output;
  //return structuredClone(output);
  //return JSON.parse(JSON.stringify(output));
}

Object.setNested = (state, name, value) => {
  let payload = { name, value };
  let old = state,
    tmp = old,
    key = "",
    arr = payload.name.split(".");
  let getDef = (nextkey) => (isNaN(nextkey) ? {} : []);
  if (arr.length > 1) {
    arr.forEach((k, i) => {
      let pkey = isNaN(k) ? k : +k;
      let nextkey = arr[i + 1] || "";;
      old = tmp;
      tmp[pkey] = (![undefined].includes(tmp[pkey])) ? tmp[pkey] : getDef(nextkey);
      tmp = tmp[pkey];
      key = pkey;
    });
    old[key] = payload.value;
  } else {
    key = payload.name;
    old = state[key];
    state[key] = payload.value;
  }
  return state;
};

Object.defVal = (val, def, ...other) => {
  let isset = () => [undefined].includes(val) ? def : val;
  if (typeof val == "object" && !(val instanceof Array) && val !== null) {
    let name = other[0] || "";
    let getnamval = () => (val[name] === undefined ? def : val[name]);
    return name ? getnamval() : isset();
  } else if (typeof val == "function") {
    return val(this.data, this.editmode, ...other);
  } else {
    return val === undefined ? def : val;
  }
};

Object.getNested = (obj, name, def) => {
  let state = obj || {};
  let old = Object.deepClone(obj);
  let key = name;
  let arr = name.split(".");
  let ret = def;
  if (arr.length > 1) {
    let tmp = old;
    arr.forEach((k) => {
      old = Object.deepClone(tmp);
      tmp[k] = tmp[k] || {};
      tmp = tmp[k];
      key = k;
    });
    ret = Object.defVal(old[key], def);
  } else {
    ret = Object.defVal(state[key], def);
  }
  return ret;
};

Object.only = (obj, required = []) => {
  return Object.filter(obj, (v, k) => required.includes(k));
};

Object.except = (obj, excludes = []) => {
  return Object.filter(obj, (v, k) => !excludes.includes(k));
};

Object.has = (obj, required = []) => {
  return !!Object.keys(Object.only(obj, required)).length;
};
Object.hasValue = (obj) => {
  return !!Object.entries(obj)
    .map(([key, val]) => val)
    .filter((v) => v).length;
};

Object.deepEqual = function (obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

Object.isEmpty = (obj) => {
  return (typeof obj === "object") && Object.keys(obj).length == 0 && obj.constructor instanceof Object;
}

Object.keyify = (obj, prefix = "") => {
  return Object.keys(obj).reduce((res, el) => {
    if (
      (Array.isArray(obj[el]) && obj[el].length) ||
      (typeof obj[el] === 'object' && !Object.isEmpty(obj[el]) && ![null, undefined].includes(obj[el]))
    ) {
      return [...res, ...Object.keyify(obj[el], prefix + el + '.')];
    }
    return [...res, prefix + el];
  }, []);
}