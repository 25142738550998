export default constore(function (props) {
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = e.target.getData();
        props.services.apiCurd("Frontend",{
            tablename: "enquiry",
            action:"create",
            ...data
        }).then(({data})=>{
            e.target.reset();
            alert("Enquiry Sent Successfully");
        });
    }
    return <div class="p-30 mt-0 bg-theme-colored">
        <h3 class="title-pattern mt-0"><span class="text-white">Request <span class="text-theme-color-2">Information</span></span></h3>

        <form id="reservation_form" name="reservation_form" class="reservation-form mt-20" onSubmit={(e) => handleSubmit(e)} >
            <div class="row">
                <div class="col-sm-12">
                    <AppInput name="name" placeholder="Enter Name" required={true} ></AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput type="email" name="email" placeholder="Enter Email" required={true} ></AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput type="tel" name="phone" placeholder="Enter Phone No. 9XXXXXXXXX" required={true} ></AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput
                        type="select"
                        choose="Country"
                        refers="states"
                        referselect="distinct country_code as key,country as label"
                        name="country"
                        placeholder="Enter Country"
                        storekey="request.country_code"
                        required={true}
                    >

                    </AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput 
                        type="select" 
                        choose="State" 
                        name="state" 
                        referselect="distinct code as key,name as label"
                        placeholder="Enter State"
                        query={{
                            country_code: "request.country_code"
                        }} 
                        refers="states"
                        storekey="request.state_code" 
                        required={true}
                        >

                    </AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput 
                        type="select" 
                        choose="City" 
                        name="city" 
                        placeholder="Enter City" 
                        query={{
                            state_code: "request.state_code"
                        }}
                        refers="cities" 
                        storekey="request.city" 
                        required={true}
                        >
                    </AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput 
                        type="select" 
                        choose="Service" 
                        name="service_id" 
                        refers="services"
                        referselect="id as key,title as label"
                        placeholder="Select Service" 
                        required={true}
                        >
                    </AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput type="text" name="company_name" placeholder="Enter Company Name" ></AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput type="text" name="company_website" placeholder="Enter Company Website" ></AppInput>
                </div>
                <div class="col-sm-12">
                    <AppInput 
                        type="textarea" 
                        name="message" 
                        placeholder="Enter Message"
                        required={true} 
                        >
                    </AppInput>
                </div>
                <div class="col-sm-12">
                    <div class="form-group mb-0 mt-10">
                        <input name="form_botcheck" class="form-control" type="hidden" value="" />
                        <button type="submit" class="btn btn-colored btn-theme-color-2 text-white btn-lg btn-block" data-loading-text="Please wait...">Submit Request</button>
                    </div>
                </div>
            </div>
        </form>

    </div>
}, "RequestInfo");