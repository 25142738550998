import React, { useState } from 'react';

const AppSearchPopup = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedService, setSelectedService] = useState('');

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
  };

  return (
    <div>
      <button onClick={togglePopup}>Search Icon</button>

      {isPopupOpen && (
        <div className="popup">
          <label>
            Select Country:
            <select value={selectedCountry} onChange={handleCountryChange}>
              <option value="usa">USA</option>
              <option value="canada">Canada</option>
              {/* Add more countries as needed */}
            </select>
          </label>

          <label>
            Select City:
            <select value={selectedCity} onChange={handleCityChange}>
              {/* Populate cities based on the selected country */}
              {selectedCountry === 'usa' && (
                <>
                  <option value="ny">New York</option>
                  <option value="la">Los Angeles</option>
                </>
              )}
              {selectedCountry === 'canada' && (
                <>
                  <option value="toronto">Toronto</option>
                  <option value="vancouver">Vancouver</option>
                </>
              )}
              {/* Add more cities as needed */}
            </select>
          </label>

          <label>
            Select Service:
            <select value={selectedService} onChange={handleServiceChange}>
              <option value="service1">Service 1</option>
              <option value="service2">Service 2</option>
              {/* Add more services as needed */}
            </select>
          </label>

          <button onClick={togglePopup}>X</button>
        </div>
      )}
    </div>
  );
};

export default AppSearchPopup;
