import { constore } from "../utils/mixins/mixin";
import { useEffect } from "react";

export default constore(function () {
    useEffect(() => {
        $('#mailchimp-subscription-form2').ajaxChimp({
            callback: mailChimpCallBack,
            url: '//thememascot.us9.list-manage.com/subscribe/post?u=a01f440178e35febc8cf4e51f&amp;id=49d6d30e1e'
        });

        function mailChimpCallBack(resp) {
            var $mailchimpform = $('#mailchimp-subscription-form2'),
                $response = '';
            $mailchimpform.children(".alert").remove();
            if (resp.result === 'success') {
                $response = '<div className="alert alert-success"><button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + resp.msg + '</div>';
            } else if (resp.result === 'error') {
                $response = '<div className="alert alert-danger"><button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + resp.msg + '</div>';
            }
            $mailchimpform.prepend($response);
        }
    }, []);
    return (
        <section className="bg-black-333 text-center">
            <div className="container pt-30">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <h3 className="text-white">Unlimited Support &amp; Easily Customizable</h3>
                        <h5 className="text-white">Subscribe to Connect with us</h5>
                        <form id="mailchimp-subscription-form2" className="newsletter-form mt-40" novalidate="true">
                            <label for="mce-EMAIL" style="">Submitting...</label>
                            <div className="input-group"></div>
                                <input type="email" id="mce-EMAIL" data-height="45px" className="form-control input-lg" placeholder="Your Email" name="EMAIL" value="" style="height: 45px;">
                                    <span className="input-group-btn">
                                        <button type="submit" className="btn btn-colored btn-theme-colored btn-lg m-0" data-height="45px" style="height: 45px;">Subscribe</button>
                                    </span>
                            </input>
                        </form>
                    </div>
                </div>
            </div>
        </section>)
}, "AppSubscribe");



