import React, { useState } from 'react';
import { constore } from "../../utils/mixins/mixin";

const AppChangePassword = (props) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = e.target.getData();
        if(data['new-password']!=data['confirm-password']) {
            alert("Please confirm the same password which you have added in to new password");
        }
        props.services.apiCurd("Frontend",{
            action:"changepassword",
            ...data
        }).then(()=>{
            alert("Password changed successfully");
        });
    };

    return (

        <div className="container ">

            <div id="accordion1" className="panel-group accordion ">
                <div className="panel ">

                    <div className="row col-md-12">
                        <div className="col-md-7">

                            <div className="card card-outline-secondary">
                                <div className="card-header">
                                    <h3 className="text-center sunflower sans-serif text-theme">Change Password</h3>
                                </div>
                                <br/>
                                <div className="card-body">
                                    <form className="form" role="form" autoComplete="off" onSubmit={handleSubmit}>
                                        <AppInput type="password" label="Current Password" name="password" required info={"Type your existing password"} />
                                        <AppInput type="password" label="New Password" name="new-password" required info={"The password must be 8-20 characters, and must not contain spaces."} />
                                        <AppInput type="password" label="Confirm Password" name="confirm-password" required info={"To confirm, type the new password again."} />
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-theme-colored btn-block float-right" style={{width:'50%',margin:'auto'}}>
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default constore(AppChangePassword);
