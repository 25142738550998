import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppServiceDetails from "../../components/AppServiceDetails";
import AppWhyYouChoose from "../home/AppWhyYouChoose";
import AppKeyFeature from "../home/AppKeyFeature";
import AppGetStarted from "../home/AppGetStarted";
import AppRegistrationForm from "../../components/AppRegistrationForm";
import AppUploadPhotosTitleLabel from "../uploadphotos/AppUploadPhotosTitleLabel";
import AppFAQSection from "../../components/AppFAQSection";
import './servicedetails.css'; 

const ServiceDetails = (props) => {
    const getService = (name) => props.getProps('data.service.' + name);
    const serviceViewData = getService("view_data");

    return (
        <>
            <AppServiceDetails />
            <AppUploadPhotosTitleLabel/>
            <AppWhyYouChoose data={serviceViewData} />
            <AppKeyFeature />
            <AppUploadPhotosTitleLabel/>
            <AppGetStarted />
            <AppFAQSection data={serviceViewData} />
            <AppRegistrationForm title="Make a Enquiry Now!" needRequest={true} payload={{"enquiry_type":"others"}} />
            <AppFooter />
        </>
    );
};

export default constore(ServiceDetails, "ServiceDetails");
