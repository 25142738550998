import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
    return (
        <>
            <section id="home">
                <div className="maximage-slider responsive-banner">
                    <div id="maximage" className="mc-cycle" style={{ height: '919px', width: '1920px' }}>
                        <div className="mc-image" title="" style={{ backgroundImage: 'url("/images/slider/b7.jpg")', height: '919px', width: '1920px' }} data-href=""></div>
                    </div>
                </div>
                <div className="display-table">
                    <div className="display-table-cell">
                        <div className="slider-size">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="pt-50 pb-50"></div>
                                </div>
                            </div>
                        </div>
                        <div className="border-1px slider-head">
                            <h3 className="mt-0 pt-5 text-white text-center slider-head-font">Professional Photo Editing Service for 360 Degree Photographers</h3>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container pt-50 ">
                <div className="section-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="meet-doctors">
                                <h1 className="mt-0 font-29 text-center pb-20">
                                    <span className="text-theme-color-2">Product Photo Editing Services </span></h1>
                                <div style={{ textAlign: 'justify' }}>
                                    <p>Product photo editing coins to every operation that can be applied to a product image for visual enhancement. These services prove to
                                        be the solution for all unpleasant elements and characteristics that may disturb the attention or affect the viewing pleasure of audiences.
                                        Influencing each buyer's attention, ColourCubz's photo editing experts edit product images to enhance their appeal which in turn maximizes
                                        the sales and increases the ROI. </p>
                                    <p>The first impression on product photos proves how products can make effects on the buying decisions of your audiences.
                                        While defying chances for audiences to touch or feel the product, only quality images will help potential customers in a better
                                        understanding of what you are selling, especially with multiple angle product images. With high-quality product images, customers
                                        can get comprehensive details such as color, texture and craftsmanship details. Good grade product images will not only let you stand
                                        aside from your competitors but also encourage social media sharing, and in turn brand development.</p>
                                    <p>ncorporating the addition of new details or fine retouches will improve the overall appealing of product images grabbing attention
                                        from online buyers. Our experts are well-versed in understanding the exact requirements of eCommerce, hospital,
                                        fashion industries, and implementation of best practices in solving each problem with effective approaches.
                                        Thus with experience in diverse industry verticals, we assure skillful management of finely-tuned processes that will maximize
                                        efficiency and timeliness. Our time-to-time quality checking processes will ensure every resultant retouched image to have
                                        best-in-industry standards. </p><p>We offer product photo retouching services to leading eCommerce stores such as Amazon,
                                            eBay, Etsy, AliExpress, Rakuten, Flipkart, Taobao, etc fashion &amp; apparel brands, jewelry, manufacturers, and catalog
                                            companies across the world. We also give ample support to diverse eCommerce platforms ranging from Shopify, Woocommerce,
                                            , BigCommerce, Yo! Kart, Opencart, Prestashop, Volusion, Weebly to Squarespace, and Big Cartel.</p>
                                    <h3 className="text-center font-26 pt-10 pb-20 text-theme-color-2"> Product Photo Retouching Techniques we use:</h3>
                                    <p> Even professional photos shot in perfectly staged studios will require amicable retouching assistance.But to get full
                                        advantage we use best - in -class and result - oriented product photo editing techniques.The techniques are stated below: </p>
                                    <div className="col-md-12 pt-20">
                                        <div className="col-md-4">
                                            <p><strong className="font-16 text-theme-color-sky"> Image Clipping services </strong></p>
                                            <p className="text-justify pb-20"> Adding depth to product photographs by removing extraneous background clutter our editors
                                                will enhance the overall appeal of product images.</p>
                                            <p><strong className="font-16 text-theme-color-sky"> Reflection / Shadow Effects </strong></p>
                                            <p className="text-justify pb-20"> Development of realistic shadows or reflections and blending it with product images
                                                creating a magical effect to images. </p>
                                            <p><strong className="font-16 text-theme-color-sky"> Ghost Mannequin Effects </strong></p>
                                            <p className="text-justify"> Addition of depth to product images by creating the best ghost mannequin effects.Client requirements
                                                with either the full mannequin, neck or the joint will be sufficed. </p></div>
                                        <div className="col-md-4">
                                            <p><strong className="font-16 text-theme-color-sky"> Batch Image Editing </strong></p>
                                            <p className="text-justify pb-20"> Application of similar effects such as color - grading, culling, shadow application, cropping, etc on a batch of images in
                                                specific formats. </p>
                                            <p><strong className="font-16 text-theme-color-sky"> Reflection / Shadow Effects </strong></p>
                                            <p className="text-justify pb-20"> Addition of effects such as drop shadowing, original shadowing, reflection shadowing and soft shadowing to product images. </p>
                                            <p><strong className="font-16 text-theme-color-sky"> Raw Image Conversion and Culling </strong></p>
                                            <p className="text-justify"> Conversion of RAW images to formats such as JPG, TIFF, PSD, BMP, GIF, etc using Lightroom. </p></div>
                                        <div className="col-md-4">
                                            <p><strong className="font-16 text-theme-color-sky"> Color Correction and Editing </strong></p>
                                            <p className="text-justify pb-20"> Performing color adjustments sensibly with shadows, lighting, color, and exposure instilling best effects &amp; color tones
                                                and white color balancing. </p>
                                            <p><strong className="font-16 text-theme-color-sky"> Photo Masking </strong></p>
                                            <p className="text-justify pb-20"> Useing effective image masking techniques, such as alpha channel, layer, and clipping and masking processes to hide
                                                transparent objects. </p>
                                            <p><strong className="font-16 text-theme-color-sky"> 3D/360° Packshot Retouching </strong></p>
                                            <p className="text-justify"> State-of-the-art 3D/360 degree image retouching services leveraged for the achievement of best image appeal. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 blog-pull-right mob-hide">
                            <div className="row">
                                <h2 className="mt-0 line-height-1 font-28 text-center pb-50">
                                    <span className="text-theme-color-2"> Our Product Photo Editing Services</span></h2>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s2.jpg" className="sub-width" /></div></div>
                                            <div className="col-md-7 ">
                                                <div><p className="font-14 text-uppercase"><strong> Jewelry Photo Editing</strong></p>
                                                    <p className="font-11">Retouching of jewelry images by blending shadows, altering backgr...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s3.jpg" className="sub-width" /></div></div>
                                            <div className="col-md-7 ">
                                                <div><p className="font-14 text-uppercase"><strong> Apparel Photo Editing</strong></p>
                                                    <p className="font-11">Processing of apparel images by removal/addition of unwanted acce...</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s4.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div><p className="font-14 text-uppercase"><strong> Furniture Photo Editing</strong></p>
                                                    <p className="font-11">Crafting furniture product images by color and lighting correctio...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s1.jpg" className="sub-width" /></div></div>
                                            <div className="col-md-7 ">
                                                <div><p className="font-14 text-uppercase"><strong> Automobile Image Editing</strong></p>
                                                    <p className="font-11">Crafting color-rich Automobile images by eliminating/adjusting un...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className=" mob-show big-hide">
                            <div className="container">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2 className="mt-0 line-height-1 font-28 text-center pb-50">
                                                <span className="text-theme-color-2"> Our Product Photo Editing Services</span></h2>
                                            <article className="post media-post clearfix pb-10 mb-10">
                                                <a href="#" className="post-thumb mr-20">
                                                    <img alt="featured project" src="/images/services/s2.jpg" className="sub-width" /></a>
                                                <div className="post-right">
                                                    <h3 className="font-13 hide text-uppercase mt-0 mb-5"><strong>
                                                        <a href="#">Jewelry Photo Editing</a></strong></h3>
                                                    <p className="font-13 text-uppercase mt-0 mb-5">
                                                        <strong><a href="#">Jewelry Photo Editing</a></strong></p>
                                                    <p className="mb-0 font-11">Retouching of jewelry images by blending shadows, altering backgr...</p></div></article>
                                            <article className="post media-post clearfix pb-10 mb-10">
                                                <a href="#" className="post-thumb mr-20">
                                                    <img alt="featured project" src="/images/services/s3.jpg" className="sub-width" /></a>
                                                <div className="post-right">
                                                    <h3 className="font-13 hide text-uppercase mt-0 mb-5">
                                                        <strong>
                                                            <a href="#">Apparel Photo Editing</a></strong></h3>
                                                    <p className="font-13 text-uppercase mt-0 mb-5"><strong>
                                                        <a href="#">Apparel Photo Editing</a></strong></p>
                                                    <p className="mb-0 font-11">Processing of apparel images by removal/addition of unwanted acce...</p></div></article>
                                            <article className="post media-post clearfix pb-10 mb-10">
                                                <a href="#" className="post-thumb mr-20">
                                                    <img alt="featured project" src="/images/services/s4.jpg" className="sub-width" /></a>
                                                <div className="post-right">
                                                    <h3 className="font-13 hide text-uppercase mt-0 mb-5">
                                                        <strong><a href="#">Furniture Photo Editing</a></strong></h3>
                                                    <p className="font-13 text-uppercase mt-0 mb-5">
                                                        <strong><a href="#">Furniture Photo Editing</a></strong></p>
                                                    <p className="mb-0 font-11">Crafting furniture product images by color and lighting correctio...</p></div></article>
                                            <article className="post media-post clearfix pb-10 mb-10">
                                                <a href="#" className="post-thumb mr-20">
                                                    <img alt="featured project" src="/images/services/s1.jpg" className="sub-width" /></a>
                                                <div className="post-right">
                                                    <h3 className="font-13 hide text-uppercase mt-0 mb-5"><strong>
                                                        <a href="#">Automobile Image Editing</a></strong></h3>
                                                    <p className="font-13 text-uppercase mt-0 mb-5"><strong>
                                                        <a href="#">Automobile Image Editing</a></strong></p>
                                                    <p className="mb-0 font-11">Crafting color-rich Automobile images by eliminating/adjusting un...</p></div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="container pt-0 pb-20">
                            <p>With all the services acknowledged above, our editors can highlight your images efficiently with natural imaging. Using diverse tools and appropriate
                                software our experts will embellish the images even the bad shots. Offering prize-winning results our professional editors assists eCommerce businesses
                                effectively. Analyzes each aspect of the image, our experts will plan accordingly for each image. With the eye of detailing we are always focused on
                                bettering the image. Based on the requirements of eCommerce and other businesses our image editors use best-in-class techniques and technology for
                                addition/elimination of existing elements and inturn enhance them. With uncompromised passion, hard work, skill, and well aided with the best in class
                                technology needed for product photo retouching, Colourcubz provides all-inclusive solutions to big and small Product photo retouching requirements.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-lighter">
                <h3 className="text-center city-head-font pb-30 pt-20 text-theme-colored"> Our Pricing Packages for Product Photo Editing Services </h3>
                <div className="section-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                                <div className="pricing-table maxwidth400">
                                    <div className="font-16 pl-20 text-center pr-20 p-10" style={{ backgroundColor: 'rgb(255, 0, 0)' }}>
                                        <strong className="text-white font-20 text-center">Basic</strong></div>
                                    <img src="/images/colorcubz/pricing/p1.jpg" alt="" />
                                    <div className=" bg-white border-1px p-30 pt-20 pb-30">
                                        <p className="font-28 m-0 text-center text-black">
                                            <span className="text-theme-color-2">$ </span>0.5</p>
                                        <p className="text-center font-18 ">Per Image</p>
                                        <ul className="list check theme-colored pb-0" style={{ height: '460px' }}>
                                            <li>LR Adjustments</li>
                                            <li>Basic Color Fixing</li>
                                            <li>White Balance</li>
                                            <li>Exposure</li>
                                            <li>Sharpening</li>
                                            <li>Contrast and Light Adjustments</li>
                                            <li>Removing Color Cast</li>
                                            <li>Perspective Corrections</li>
                                            <li>Cropping or Resizing</li>
                                            <li>PS Adjustments</li>
                                            <li>Slight Dust Removal</li>
                                            <li>Sky Color Enhancement</li>
                                        </ul>
                                    </div>
                                    <a href="https://www.colourcubz.com/order-summary" className="btn btn-lg btn-theme-colored  btn-block pt-15 pb-15 btn-flat">
                                        <span className="btn btn-colored btn-theme-color-2 text-uppercase text-white">Buy Now</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                                <div className="pricing-table maxwidth400">
                                    <div className="font-16 pl-20  text-center pr-20 p-10" style={{ backgroundColor: 'rgb(76, 175, 80)' }}>
                                        <strong className="text-white font-20 text-center">Pro</strong></div>
                                    <img src="/images/colorcubz/pricing/p2.jpg" alt="" />
                                    <div className=" bg-white border-1px p-30 pt-20 pb-30">
                                        <p className="font-28 m-0 text-center text-black">
                                            <span className="text-theme-color-2">$ </span>0.7</p>
                                        <p className="text-center font-18 ">Per Image</p>
                                        <ul className="list check theme-colored pb-0" style={{ height: '460px' }}>
                                            <li>Basic Corrections</li>
                                            <li>Lawn Retouch</li>
                                            <li>Drawing Fire and Adding it to Fireplaces</li>
                                            <li>Outdoor Sky Replacement</li>
                                            <li>Fixing Burnt Out Lightbulbs</li>
                                            <li>’Blueing’ Pools, Lakes, Rivers, Oceans</li>
                                            <li>Small Flash Reflection/ camera Removal</li>
                                            <li>Dust &amp; Garbage Edit</li>
                                            <li>Retouching and Masking Together Two Images/Exposures</li>
                                            <li>Small Objects Removal</li>
                                            <li>Clarity Noise &amp; Blur Reduction</li>
                                            <li>HDR Photo Editing</li>
                                            <li>Vertical &amp; Horizontal Straightening</li>
                                            <li>Windows Enhancement</li>
                                            <li>Cord Removal</li>
                                            <li>TV Screen Replacement</li>
                                        </ul>
                                    </div>
                                    <a href="https://www.colourcubz.com/order-summary" className="btn btn-lg btn-theme-colored  btn-block pt-15 pb-15 btn-flat">
                                        <span className="btn btn-colored btn-theme-color-2 text-uppercase text-white">Buy Now</span></a>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                                <div className="pricing-table maxwidth400">
                                    <div className="font-16 pl-20  text-center pr-20 p-10" style={{ backgroundColor: 'rgb(255, 0, 0)' }}>
                                        <strong className="text-white font-20 text-center">Premium</strong></div>
                                    <img src="/images/colorcubz/pricing/p3.jpg" alt="" />
                                    <div className=" bg-white border-1px p-30 pt-20 pb-30">
                                        <p className="font-28 m-0 text-center text-black">
                                            <span className="text-theme-color-2">$ </span>1.0</p>
                                        <p className="text-center font-18">Per Image</p>
                                        <ul className="list check theme-colored pb-0" style={{ height: '460px' }}>
                                            <li>Professional Corrections</li>
                                            <li>Editing and Masking Together 3-5 Images/Exposures</li>
                                            <li>Flash Shadow Removal</li>
                                            <li>Interior Sky and Grass Replacements</li>
                                            <li>Wall/Floor/Ground Cleaning</li>
                                            <li>Medium Objects Removal</li>
                                            <li>Carpets/Curtains/Bedcovers Smoothing</li></ul></div>
                                    <a href="https://www.colourcubz.com/order-summary" className="btn btn-lg btn-theme-colored  btn-block pt-15 pb-15 btn-flat">
                                        <span className="btn btn-colored btn-theme-color-2 text-uppercase text-white">Buy Now</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}, "AppProductPhotoEditingServices");
