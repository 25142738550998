import { toLower } from 'lodash';
import React from 'react'
function AppSearchLocation(props) {
    let countries = props.getProps('countries');
    return (
        <>

            <li style={{ position: 'relative' }} >
                <a href='javascript:void(0)'
                    className="btn btnborder adv_search btn-flat btn-8-25 m-0 mt-0"
                    id="advance_search">
                    <i className="fa fa-globe"  ></i>
                </a>
                <ul className='country-list' style={{zIndex:1}}>
                    <li>
                        <span></span>
                        <a href={'/'}>All</a>
                    </li>
                    {
                        countries.map((item, i) => <li key={item.code}>
                            <span>
                                <img src={'https://flagsapi.com/' + item.code.toUpperCase() + '/flat/16.png'} ></img>
                            </span>
                            <a href={props.buildpath(`/${item.code.toLowerCase()}`)}>{item.name.toUpperCase()}</a>
                        </li>)
                    }
                </ul>
            </li>
        </>

    );
}; export default constore(AppSearchLocation);