
import { useEffect } from "react";
import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
    useEffect(() => {
        $("#quick_contact_form_sidebar").validate({
            submitHandler: function (form) {
                var form_btn = $(form).find('button[type="submit"]');
                var form_result_div = '#form-result';
                $(form_result_div).remove();
                form_btn.before('<div id="form-result" className="alert alert-success" role="alert" style="display: none;"></div>');
                var form_btn_old_msg = form_btn.html();
                form_btn.html(form_btn.prop('disabled', true).data("loading-text"));
                $(form).ajaxSubmit({
                    dataType: 'json',
                    success: function (data) {
                        if (data.status == 'true') {
                            $(form).find('.form-control').val('');
                        }
                        form_btn.prop('disabled', false).html(form_btn_old_msg);
                        $(form_result_div).html(data.message).fadeIn('slow');
                        setTimeout(function () { $(form_result_div).fadeOut('slow') }, 6000);
                    }
                });
            }
        });
    }, []);

    return (<section>
        <div className="container">
            <div className="row">
                <div className="col-md-8 blog-pull-right">
                    <div className="single-service">
                        <img src="http://placehold.it/750x500" alt="" />
                        <h3 className="text-theme-colored line-bottom text-theme-colored">Accounting Technologies</h3>
                        <h4 className="mt-0"><span className="text-theme-color-2">Price :</span> $420</h4>
                        <ul className="review_text list-inline">
                            <li>
                                <div className="star-rating" title="Rated 4.50 out of 5"><span style={{"width": "90%"}}>4.50</span></div>
                            </li>
                        </ul>
                        <h5><em>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo unde, <span className="text-theme-color-2">accounting technologies</span> corporis dolorum blanditiis ullam officia <span className="text-theme-color-2">our university </span>natus minima fugiat repellat! Corrupti voluptatibus aperiam voluptatem. Exercitationem, placeat, cupiditate.</em></h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore suscipit, inventore aliquid incidunt, quasi error! Natus esse rem eaque asperiores eligendi dicta quidem iure, excepturi doloremque eius neque autem sint error qui tenetur, modi provident aut, maiores laudantium reiciendis expedita. Eligendi</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore  officiis quod animi possimus a, iure nam sunt quas aperiam non recusandae reprehenderit, nesciunt cumque pariatur totam repellendus delectus? Maxime quasi earum nobis, dicta, aliquam facere reiciendis, delectus voluptas, ea assumenda blanditiis placeat dignissimos quas iusto repellat cumque.</p>
                        
                        <div className="widget">
                            <h4 className="widget-title line-bottom">Opening <span className="text-theme-color-2">Hours</span></h4>
                            <div className="opening-hours">
                                <ul className="list-border">
                                    <li className="clearfix"> <span> Mon - Tues :  </span>
                                        <div className="value pull-right"> 6.00 am - 10.00 pm </div>
                                    </li>
                                    <li className="clearfix"> <span> Wednes - Thurs :</span>
                                        <div className="value pull-right"> 8.00 am - 6.00 pm </div>
                                    </li>
                                    <li className="clearfix"> <span> Fri : </span>
                                        <div className="value pull-right"> 3.00 pm - 8.00 pm </div>
                                    </li>
                                    <li className="clearfix"> <span> Sun : </span>
                                        <div className="value pull-right"> Colosed </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}, "AppProductDetails");
