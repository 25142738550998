import { useState } from 'react';
import StripeContainer from './StripeContainer';

//  import "./stripe.css";

import React from 'react'

const AppStripe = () => {
    const [showItem, setShowItem] = useState(false);
  return (
    <>
        <div className='App'>
			<h4>The Colorcubz</h4>
			{showItem ? (
				<StripeContainer />
			) : (
				<>
					<h3>$10.00</h3>
					
					<button onClick={() => setShowItem(true)}>Purchase </button>
				</>
			)}
		</div>
    </>
  )
}

export default AppStripe



