import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppTermsAndConditions from "../../components/AppTermsAndConditions";

export default constore(function () {
    return (
        <>
            <AppTermsAndConditions/>
            <AppFooter/>
        </>
    )
}, "terms-conditions");