import devapis from "./dev.endpoints";
import stagingapis from "./staging.endpoints";
import prodapis from "./prod.endpoints";
const localtodev = "http://localhost:3000,http://localhost:3001,http://localhost:8000,";
const localtostage = "";
const localtoprod = "";

export const endpoints = {
    "dev": {
        "base": `${localtodev}http://edm.colourcubz.test`,
        "api_base": "http://edm.colourcubz.test/api",
        // "api_base": "http://localhost:8000/api",
        "static_token": "",
        ...devapis
    },
    "sumanth": {
        "base": `${localtodev}http://edm.colourcubz.test:8080`,
        "api_base": "http://edm.colourcubz.test:8080/api",
        "static_token": "",
        ...devapis
    },
    "harshitha": {
        "base": `${localtodev}http://edn_colorcubz.test`,
        "api_base": "http://edn_colorcubz.test/api",
        "static_token": "",
        ...devapis
    },
    "goutham":{
        "base": `${localtodev}http://edm.colorcubz.test`,
        "api_base": "http://edm.colorcubz.test/api",
        "static_token": "",
        ...devapis
    },
    "staging": {
        "base": `${localtostage}http://colourcubz.dreamtree.in.net,https://colourcubz.dreamtree.in.net`,
        "api_base": "https://colourcubz.dreamtree.in.net/api",
        "static_token": "",
        ...stagingapis
    },
    "production": {
        "base": `${localtoprod}http://165.232.180.227,http://colourcubz.com,https://colourcubz.com,https://www.colourcubz.com`,
        "api_base": "https://www.colourcubz.com/api",
        "static_token": "",
        ...prodapis
    }
}

export default endpoints;