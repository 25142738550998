import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppPayPal from "./AppPayPal";



export default constore(function () {
    return (
        <>
            <AppPayPal/>
            <AppFooter/>
        </>
    )
}, "paypal");