import React from 'react'
import AppCmOrders from './AppCmOrders'
import AppCmProcess from './AppCmProcess'
import { constore } from '../../utils/mixins/mixin'
import AppChangePassword from './AppChangePassword'
const AppCustomerDetails = (props) => {

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="portlet light profile-sidebar-portlet bordered">
              <div className="profile-userpic">
                <img src="https://bootdey.com/img/Content/avatar/avatar6.png" className="img-responsive" alt="customer_details" /> </div>
              <div className="profile-usertitle">
                <h1 className="profile-usertitle-name">
                  {
                    props.server?.user?.name
                  }
                </h1>
                <div className="profile-usertitle-job">
                  {
                    (props.server?.user?.roles || []).join(",")
                  }
                </div>
              </div>

              <div className="profile-usermenu text-center">
                <ul className="nav">
                  <li className="active">
                    <a href="#myorderitems" role="tab" data-toggle="tab">
                      <i className="icon-home"></i>My Ordered Items</a>
                  </li>
                  <li>
                    <a href="#processed" role="tab" data-toggle="tab" aria-controls="changepassword" >
                      <i className="icon-settings"></i>My Processed Files</a>
                  </li>
                  <li>
                    <a href="#changepassword" role="tab" data-toggle="tab" aria-controls="changepassword" >
                      <i className="icon-settings"></i> Change Password </a>
                  </li>
                  <li>
                  <a href={"/logout"} title="Logout" >
                    <i className="fa fa-sign-out"></i>Logout
                  </a>    
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="tab-content">
              <div role="tabpanel" className="tab-pane active" id="myorderitems"><AppCmOrders /></div>
              <div role="tabpanel" className="tab-pane" id="processed"><AppCmProcess /></div>
              <div role="tabpanel" className="tab-pane" id="changepassword"><AppChangePassword /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default constore(AppCustomerDetails);