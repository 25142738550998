import { constore } from "../../utils/mixins/mixin"

const handleEventChange = (e,name,props)=>{
    const file = e.target.files[0];
    const maxSizeInBytes = 50 * 1024 * 1024;
    const key = name || '';
    let value = e.target[key]||'';
    if (file && file.size > maxSizeInBytes) {
        alert('File size exceeds the limit 10mb. Please choose a smaller file.');
        e.target.value = null; 
        return;
    } else {
        if(props.onChange) {
            props.onChange(value,e);
        }
    }
}

export default constore((props) => {
    const { type, name, required, placeholder } = props;
    return <div class="form-group d-flex items-center justify-center gap-2">
        <input type={type} id={name}  name={name}  required={required} placeholder={placeholder} class={Object.className({ '!w-auto': true, disabled: props?.disabled })} onChange={(e) => handleEventChange(e, "value",props)} />
        <span class="text-xs check-label">
            {props.label && <span>{props.label}</span>}
        </span>
    </div>
},"AppFilePicker");  