import { constore } from "../../utils/mixins/mixin";
import AppLoader from "./AppLoader";
import AppHeader from "./AppHeader";

export default constore(function (props) {
    return (
        <>
            <div>
                <AppHeader/>
                {props.children}
            </div>
            <AppLoader />
        </>
    )
}, 'AppLayout');