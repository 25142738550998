import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppCustomerDetails from "./AppCustomerDetails";
import "./Customer-details.css";
import AppCmOrders from "./AppCmOrders";


export default constore(function () {
    return (
        <>
            {/* <AppCmOrders/> */}
            <AppCustomerDetails/>
            <AppFooter/>
        </>
    )
}, "customer-details");