import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import pages from "../pages";
import App from "../App";

export const getComponent = (o) => ({
  path: o.path+"/:slug?/:country?",
  element: (
    <App path={o.path}>
        <o.Element />
    </App>
  )
})

console.log({pages});

const CustomNavigate = (to)=>{
  const props = server.env().props;
  const page  = Object.values(pages).find(o=>o.startpath=="/"+props.pagename);
  if(page) {
    return getComponent(page).element;
  } else {
    return <Navigate to={to} />;
  }
}

export const getRouter = (values, to = "/home") => {
  const routes = [
    {
      path:"/",
      element:getComponent(values.find(o=>o.startpath=="/home")).element
    },
    ...values.map(getComponent),
    {
      path: "*",
      title: "*",
      element: CustomNavigate(to),
    }
  ];
  console.log({routes});
  return createBrowserRouter(routes);
};
export default getRouter(Object.values(pages));
