export default class Emitter {
    constructor() {
        this.removeListeners();
    }

    remove(key) {
        delete this.listners[key];
    }

    removeListeners() {
        this.listners = {};
        this.nodes = {};
        this.nodecallbacks = {};
        this.emittedData = {};
    }

    onTrigger(key, node = null, callback = null) {
        this.nodes[key] = this.nodes[key] ? this.nodes[key] : [];
        if (node) {
            if (!this.nodes[key].includes(node)) {
                this.nodes[key].push(node);
            }
            if (callback) {
                node.triggercallbacks = node.triggercallbacks || {};
                node.triggercallbacks[key] = callback;
                this.nodecallbacks[key] = callback;
                console.log(`${key} Emit Subscribed`);
            }
            let emitedData = this.emittedData[key];
            if (emitedData?.triggred) {
                setTimeout(() => {
                    callback(...emitedData.params);
                }, 100);
            }
        }
    }

    on(key, callback) {
        this.listners[key] = callback;
    }

    hasListener(key) {
        return !!this.listners[key];
    }

    debounce(timeout=500) {
        return {
            ...this,
            trigger:(...args)=>{
                return new Promise((resolve)=>{
                    clearTimeout(this.st);
                    this.st = setTimeout(async ()=>{
                        let ret = this.trigger(...args);
                        if(ret instanceof Promise) { resolve(await ret); }
                        else { resolve(ret); }
                    },timeout)
                })
            }
        };
    }

    trigger(key, ...arg) {
        let callback = this.listners[key] || (() => '');
        let touchedNodes = this.nodes[key] || [];
        console.log(`${key} Emitted`, ...arg);
        touchedNodes.forEach((node) => {
            let triggercallbacks = node.triggercallbacks || {};
            let nodecall = triggercallbacks[key] || this.nodecallbacks[key] || node[key];
            if (nodecall instanceof Function) {
                console.log(`${key} Emit Resolved`, {
                    key,
                    node: this.nodecallbacks[key],
                    trigger: triggercallbacks[key],
                    component: node.componentName,
                    params: arg,
                });
                nodecall.call(node, ...arg);
            }
        });
        this.emittedData[key] = {
            triggered: true,
            params: arg,
        };
        console.log("Callback",callback);
        let ret = callback(...arg);
        if (ret instanceof Promise) {
            return new Promise((resolve, reject) => {
                Promise.all([ret]).then(([val]) => resolve(val));
            });
        } else {
            return ret;
        }
    }
}
