import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppPortraitRetouchingServices from "../servicedetails/AppPortraitRetouchingServices"
export default constore(function () {
    return (
        <>
            <AppPortraitRetouchingServices/>
            <AppFooter/>
            </>
    )
}, "AppProductDetails");