import { constore } from "../../utils/mixins/mixin";
import AppSearchLocation from "./AppSearchLocation";

export default constore(function () {
    return (
        <>
        <AppSearchLocation/>
        </>
    )
}, "searchlocation");





