import { constore } from "../utils/mixins/mixin";
import AppInput from "./partials/AppInput";
import AppReCaptcha from "./partials/AppReCaptcha";

const handleSubmit = (e, props) => {
  e.preventDefault();
  let data = e.target.getData();
  props.services.apiCurd("Frontend", {
    tablename: "enquiry",
    action: "create",
    browser_fingerprint: props.services.getBrowserFingerprint().hash(),
    ...data,
    enquiry_type: "guest_users",
    ...(props.payload || {})
  }).then(({ data }) => {
    e.target.reset();
    alert("Thank you for submitting the Guest User form!");
  });
}

export default constore(function (props) {
  const content = props?.server?.props?.contentdata;
  const userEmail = props.server?.user?.email;
  console.log({ content });
  const getContent = (name) => props.getcontent('home.guestuser' + (name ? `.${name}` : ''));

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <h1 className="text-center font-30 text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('guestuser_title')}</h1>
            <form action="/submit-form" method="post" onSubmit={(e) => handleSubmit(e, props)} className="well mt-4">
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-envelope" style={{ fontSize: '14px' }}></i>
                  </span>
                  <AppInput type="email" name="email" placeholder="Enter Your Email Address " required={true} defaultValue={userEmail} ></AppInput>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-upload" style={{ fontSize: '14px' }}></i>
                  </span>
                  <AppInput type="file" id="serviceFile" name="service_file_doc" required />
                </div>
              </div>
              <div className="form-group">
                <div className="input-group" style={{ fontSize: '14px' }}>
                  <span className="input-group-addon">
                    <i className="glyphicon glyphicon-pencil" style={{ fontSize: '14px' }}></i>
                  </span>
                  <AppInput
                                                    type="textarea"
                                                    name="message"
                                                    data-maxlen="200"
                                                    placeholder="Enter Message(200 words limit)"
                                                    required={true}
                                                >
                                                </AppInput>
                </div>
              </div>
              <div className="form-group" style={{ display: 'flex' }}>
                <div style={{ margin: 'auto', display: 'inline-block', marginBottom: '5px' }}>
                  <AppReCaptcha />
                </div>
              </div>
              <button type="submit" className="btn btn-theme-colored btn-block">{getContent('submit_label')}</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}, "AppGuestUser");
