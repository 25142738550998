export default {
    getBrowserFingerprint() {
        const navigator = globalThis.navigator;
        const screen = globalThis.screen;
        const navigatorData = {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            languages: navigator.languages.join(', '),
            deviceMemory: (navigator).deviceMemory || 'unknown',
            hardwareConcurrency: navigator.hardwareConcurrency || 'unknown',
        };
    
        const screenData = {
            width: screen.width,
            height: screen.height,
            colorDepth: screen.colorDepth,
            pixelDepth: screen.pixelDepth,
            availWidth: screen.availWidth,
            availHeight: screen.availHeight
        };
    
        const browserData = {
            cookiesEnabled: navigator.cookieEnabled,
            doNotTrack: navigator.doNotTrack || 'unknown',
            timezoneOffset: new Date().getTimezoneOffset(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
    
        const data = {
            navigator: navigatorData,
            screen: screenData,
            browser: browserData,
        };
    
        return JSON.stringify(data);
    },
    apiCurd(modelname,data) {
        let $request =  this.apis.request(this.endpoint.CURD,data).urltransform({
            modelname
        });
        if(Object.values(data).find(o=>o instanceof File || o instanceof FileList)) {
            $request = $request.multipart();
        }
        return $request.exec().get();
    }
}