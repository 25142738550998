import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { constore } from "../../utils/mixins/mixin";
const AppReCaptcha = ({ server, required }) => {
    const sitekey = server.sitekey;
    let [value,seValue] = useState("");
    return <div>
        <input type="hidden" name="g-recaptcha-response" value={value} ></input>
        <ReCAPTCHA
            sitekey={sitekey}
            onChange={(v)=>seValue(v)}
            size="v3"
            required={required}
        />
    </div>
}

export default constore(AppReCaptcha,"AppReCaptcha");