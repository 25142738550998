import { useEffect } from "react";
import { constore } from "../../utils/mixins/mixin";

export default constore((props)=>{
    useEffect(()=>{
        props.services.apiCurd("User",{
            action:"logout"
        }).finally(()=>{
            props.redirect("/");
        });
    },[])
    return (<div>
        Logging out...
    </div>);
});