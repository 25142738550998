const getapi = function () {
    let api = window.api;
    api = window.getpathkey(api);
    api = {
        ...api,
        ...window.getpathkey(api)
    };
    return api;
};
export const commonService = {
    api: getapi,
    apiSuccess(resolve = ((v) => (v)), responce = {}) {
        return resolve(responce.data);
    },
    applogout() {
        return new Promise((resolve, reject) => {
            app.props.store("authResponce", false);
            app.props.store("user", false);
            localStorage.clear();
            resolve();
        });
    },
    apiFailure({ reject, resolve }, error) {
        let app = window?.app;
        if ([400].includes(error?.response?.status)) {
            let message = (
                error.response?.data?.issues?.error ||
                error.response?.data?.issues?.message ||
                error.response?.data?.issues?.data.error ||
                error.response?.data?.message ||
                error.response?.data?.msg
            )
            alert(message).promise.then(() => {
                if (error.response?.data?.redirectlink) {
                    const redirectLink = error.response.data.redirectlink;
                    window.location.href = redirectLink;
                }
            });
            
        } else if (error?.response?.status == 500) {
            let message = (
                error.response?.data?.issues?.error
            )
            alert(message);
        } else if (error?.response?.status == 502 || error?.response?.status == 404) {
            let message = (
                error.response?.data?.issues?.message
            )
            alert(message);
        }
        else if (error?.response?.status == 401) {
            app?.props?.api?.applogout();
            // app.props.navigate("/home");
        }
        console.log({ error }, error?.response?.status);
        reject(error);
    },
    endpoint2url(endpoint) {
        let api = getapi();
        return endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
    },
    filterPayload(obj, inc = []) {
        const filter = (obj, predicate) =>
            Object.keys(obj)
                .filter((key) => predicate(obj[key], key, obj))
                .reduce((res, key) => {
                    res[key] = obj[key];
                    return res;
                }, {});
        return filter(obj, (v, k) => inc.includes(k));
    },
    request(endobj = "", post = {}, header = {}) {
        let endpoint = endobj.url;
        let objmethod = endobj.method;
        let payload = Object.keys(endobj.payload || {}).length ? endobj.payload : post;
        let data = this.filterPayload(post, Object.keys(payload));
        let axios = window.axios;
        let api = getapi();
        //console.log("request",window?.app?.props?.$store);
        let authorization = (window?.app?.props?.$store?.auth || localStorage?.redux?.parse()?.auth || "")
        let refresh = (window?.app?.props?.$store?.user?.refresh || localStorage?.redux?.parse()?.user?.refresh||"")
        let inHeaders = {
            headers: {
                authorization: "Bearer "+authorization,
                refresh,
                ...header
            },
        };
        let bundel = {
            payload: {},
            urltransform: (data = app.mixin.params(), apiendobj = endobj) => {
                let func = apiendobj.url;
                if (func instanceof Function) {
                    endpoint = func(data);
                } else {
                    endpoint = Object.entries(data).reduce((url, [key, val]) => {
                        return url.split(`{${key}}`).join(val);
                    }, apiendobj.url);
                }
                bundel.url = endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
                return bundel;
            },
            multipart: () => {
                inHeaders.headers = {
                    ...(inHeaders.headers || {}),
                    "Content-Type": "multipart/form-data"
                };
                return bundel;
            },
            config: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders) : reqconfig;
                inHeaders = {
                    ...inHeaders,
                    ...temp
                }
                return bundel;
            },
            headers: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders.headers) : reqconfig;
                inHeaders.headers = {
                    ...inHeaders.headers,
                    ...temp
                }
                return bundel;
            },
            transform(callback = ((v) => (v))) {
                data = callback(post);
                return bundel;
            },
            geturl(data = {}) {
                return bundel.url + Object.encodeuri(data)
            },
            exec: (message = false, method = "get") => {
                bundel.results = new Promise((resolve, reject) => {
                    let app = window?.app;
                    let loaded = { close: () => ('') };
                    if (window.loader) {
                        loaded = loader(message);
                    }
                    method = objmethod || method || (data instanceof FormData ? "post" : "get");
                    let url = this.endpoint2url(endpoint);
                    let isGet = method == "get";
                    let isDelete = method == "delete";
                    url = isGet ? url + Object.encodeuri(data) : url;
                    let requestarr = isGet ? ({ url, inHeaders }) : ({ url, data, inHeaders });
                    requestarr = isDelete ? ({
                        url, inHeaders: {
                            ...inHeaders,
                            data
                        }
                    }) : requestarr;
                    axios[method](...Object.values(requestarr))
                        .then((...arg) => this.apiSuccess(resolve, ...arg))
                        .catch((...arg) => this.apiFailure({ reject, resolve }, ...arg))
                        .finally(() => loaded.close());
                });
                return bundel;
            },
            log() {
                console.log({ endobj, post, data, Headers: inHeaders, responce: bundel.results });
                return bundel;
            },
            mapresults(callback = ((v) => (v))) {
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        let resultdata = callback(data);
                        if (resultdata instanceof Promise) {
                            resultdata = await resultdata;
                        }
                        resolve(resultdata)
                    }).catch(reject);
                });
                bundel.results = newpromise;
                return bundel;
            },
            get() {
                let app = window?.app;
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        resolve(data)
                    }).catch((...args) => {
                        console.log("bundel error", ...args);
                        reject(...args);
                    }).then(() => {
                        
                    });
                });
                return newpromise;
            }
        };
        return bundel;
    },
    mokeapi(callback = (() => ('')), message = "Getting data please wait...", delay = 1000) {
        return new Promise((resolve, reject) => {
            let app = window?.app;
            let id = Math.random().toString().slice(2);
            message && app.mixin.pushRequest({ message, id });
            setTimeout(() => {
                resolve(callback());
                message && app.mixin.popRequest(id);
            }, delay);
        });
    }
}

export default commonService;