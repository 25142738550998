import { constore } from "../utils/mixins/mixin";
import React, { useState } from "react";
import AppUploadPhotosTitleLabel from "../pages/uploadphotos/AppUploadPhotosTitleLabel";


export const Pragraph = (props)=>{
    let [readMore, setReadMore] = useState(false);
    let content = props.content || props.children;
    let parr = content.split('\n').filter(v => v);
    const isMobile = document.body.clientWidth <= 900;
    return <>
        {
            parr.map((v, i, arr) => {
                if (isMobile && i == 0 && arr.length>1) {
                    if (!readMore) {
                        return <p>
                            {v}
                            <span className={`ml-auto mob-view ${isMobile && !readMore ? 'show-read-more' : 'hide-read-more'}`} onClick={() => setReadMore(true)}>
                                Read more..
                            </span>
                        </p>
                    } else {
                        return <p>{v}</p>;
                    }
                } else if (!isMobile || readMore || arr.length==1) {
                    return <p>{v}</p>
                }
            })
        }
    </>
}

export default constore(function (props) {
    let [readMore, setReadMore] = useState(false);
    const content = props?.server?.props?.contentdata;
    const getContent = (name) => props.getcontent('about' + (name ? `.${name}` : ''));
    const getService = (name) => props.getcontent('home' + (name ? `.${name}` : ''));
    const isMobile = document.body.clientWidth <= 900; 

    const blockColors = ['#f26679', '#00ced1', '#e0d74c', '#66c8f2'];
 

    return (
        <body>
        <div id="app">
            <div className="divider parallax" style={{ backgroundPosition: '50% 39px' }}>
                <section>
                    <img
                        className='w-full'
                        src={`/files/secure/aboutus.jpg?fileId=221&rd=36&asimg=1&mobile=${isMobile ? '1' : '0'}`}
                        style={{ width: '100%' }}
                        alt="About Us"
                    />

                </section>
            </div>

            <section>
                <div className="container">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="meet-doctors">
                                    <div className="mt-0 pt-10 pb-20 line-height-1 text-center">
                                        <h1 className="text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif', fontSize:'38px'}}>
                                            {getContent('title').toUpperCase()}
                                        </h1>
                                    </div>
                                    <div style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px' }}>
                                        <Pragraph content={getContent('desc')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-0 pt-5" style={{ paddingTop: '0px !important' }}>
                    <div className="row">
                        <div className="mini-info mini-info-mob">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h2 className="text-theme-colored pb-20 font text-center line-height-1 mt-0 mb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                                    {getContent('our_mission_label')}
                                </h2>
                                <br />

                                <div className="mini-text" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px' }}>
                                    <Pragraph content={getContent('our_mission_desc')} />
                                </div>
                                <br />
                                <h3 className="text-theme-colored pb-20 font text-center line-height-1 mt-0 mb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', fontSize:'32px' }}> {getContent('our_vision_label')}</h3>
                                <br />
                                <div className="mini-text" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px' }}>
                                    <Pragraph content={getContent('our_vision_desc')} />
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AppUploadPhotosTitleLabel />

            {/* <section className="bg-lighter animatedParent animateOnce pb-50 mob-hide">
                <div className="container">
                    <h3 className="text-center pb-50 text-uppercase text-bold text-theme-colored font-28" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                        TO GET STARTED
                    </h3>

                    <div className="clearfix fadeInLeft animated">
                        {Array.from({ length: 4 }).map((_, index) => (
                            <div key={index} className={`col-xs-12 col-sm-12 col-md-3 col-lg-3`} style={{ backgroundColor: blockColors[index] }}>
                                <div className="our_process_box pl-20 pr-20 pb-20 pt-40" style={{ marginBottom: '20px' }}>
                                    <div className="text-center">
                                        <i className={`fa ${['fa-cloud-upload', 'fa-edit', 'fa-picture-o', 'fa-cloud-download'][index]} d-none d-md-block`} style={{ fontSize: '88px', color: 'white' }}></i>
                                    </div>
                                    <p className="font-18 text-center">
                                        <strong className={`${index % 2 === 0 ? 'upload' : 'edit'}`}>{getService(`about.services.togetstarted_panel.${index}.title`)}</strong>
                                    </p>
                                    <p className="20" style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif' }}>
                                        {getService(`about.services.togetstarted_panel.${index}.desc`)}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}

            <section className="bg animatedParent animateOnce pb-0 mob-hide">
                <div className="container">
                    <h3 className="text-center pb-20 text-uppercase text-bold text-theme-colored font-28" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                        HOW IT WORKS?
                    </h3>
                    <img src={`/files/secure/How-it-works.jpg?fileId=246&rd=93&asimg=1&mobile=${isMobile ? '1' : '0'}`} alt="how_it_works_image"/>
                </div>
            </section>
        </div>
        </body>
    );
}, "AppAbout");







