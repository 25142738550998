
import React, { useState } from 'react';
import { constore } from '../utils/mixins/mixin';
import AppUploadPhotosTitleLabel from '../pages/uploadphotos/AppUploadPhotosTitleLabel';

// export const Pragraph = (props)=>{
//   let [readMore, setReadMore] = useState(false);
//   let content = props.content || props.children;
//   let parr = content.split('\n').filter(v => v);
//   const isMobile = document.body.clientWidth <= 900;
//   return <>
//       {
//           parr.map((v, i, arr) => {
//               if (isMobile && i == 0 && arr.length>1) {
//                   if (!readMore) {
//                       return <p>
//                           {v}
//                           <span className={`ml-auto mob-view ${isMobile && !readMore ? 'show-read-more' : 'hide-read-more'}`} onClick={() => setReadMore(true)}>
//                               Read more..
//                           </span>
//                       </p>
//                   } else {
//                       return <p>{v}</p>;
//                   }
//               } else if (!isMobile || readMore || arr.length==1) {
//                   return <p>{v}</p>
//               }
//           })
//       }
//   </>
// }

export default constore(function (props) {
  const [services, setServices] = useState(props.$node?.server?.props?.data?.services || []);
  const getService = (name) => props.getcontent('home.services' + (name ? `.${name}` : ''));
  const getContent = (name) => props.getcontent('pricing' + (name ? `.${name}` : ''));
  const isMobile = document.body.clientWidth <= 900; 
  return (
    <>
      <section id="pricing" className="pt-20 pb-0">
        <div className="container pt-30">
          <h1 className="text-center pb-30">
            <span className="text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('title')}</span>
          </h1>
          <div style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px' }}>
          {getContent('title_desc')}
          </div>
          <h2 className="text-center pb-20 pt-30">
            <span className="text-theme-colored text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} >{getContent('featurelist_subtitle')}</span>
          </h2>
          <div className="section-content">
            {services.chunk(4).map((row, rowIndex) => (
              <div className="row" key={rowIndex}>
                {row.map((col, colIndex) => (
                  <div key={colIndex} className="col-xs-12 col-sm-6 col-md-3 hvr-float-shadow mb-sm-30">
                    <div className="pricing-table pricemenu" style={{ width: '279px', margin: '0 auto', marginBottom: "10px" }}>
                      <div className="font-16 pl-20 text-bold text-center pr-20 p-10" style={{ backgroundColor: 'rgb(205, 209, 205)' }}>
                        <strong className="text-black font-16 text-center" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textTransform: 'uppercase' }}>{col.title}</strong>
                      </div>
                      <div>
                        <img src={"/files/secure/image?fileId=" + (col.image || 1) + "&asimg=1"} alt="pricing" style={{ height: '200px', width: '100%', objectFit: 'cover' }} />
                      </div>
                      <div className="bg-white border-1px p-30 pt-20 pb-20" style={{ height: '460px' }}>
                        <p className="text-center font-26 p-0 m-0">Starts</p>
                        <p className="package-type font-26 m-0 text-black">
                          <span className="text-theme-colored">From $ </span>{col.price}
                        </p>
                        <ul className="table-list list-icon theme-colored pb-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', padding: '2px' }}>
                          {([...((col.pricing_details.parse()).slice(0, 8)), ...((col.pricing_details.parse()).filter(o => o.excluded).slice(0, 8))]).map((obj, i) => (
                            <li key={i}>
                              {<i className="fa fa-check"></i>}
                              <label title={obj.title} style={{ padding: '2px', fontSize: '13px', textTransform: 'uppercase' }}>{obj.title}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <a href={props.buildpath("/" + col.name)} className="btn btn-lg btn-block pt-10 pb-10 btn-flat" style={{ backgroundColor: 'rgb(215, 219, 215)' }}>
                        <span className="btn btn-colored btn-theme-colored text-white">{getService('view_details_label')}</span>
                      </a>
                    </div>
                  </div>
                ))}
              </div> 
            ))}
          </div>

        </div>
      </section>
      <AppUploadPhotosTitleLabel/> 
      <section className="bg animatedParent animateOnce pb-0 mob-hide">
                <div className="container">
                    <h3 className="text-center pb-20 text-uppercase text-bold text-theme-colored font-28" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                        HOW IT WORKS?
                    </h3>
                    <img src={`/files/secure/How-it-works.jpg?fileId=246&rd=93&asimg=1&mobile=${isMobile ? '1' : '0'}`}/>
                </div>
            </section>
{/* 
      <section>
        <div className="container">
          <h2 className="text-center pt-10 pb-20">
            <span className="text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('sub_title')}</span>
          </h2>
          <div style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px' }}>
             <Pragraph content={getContent('sub_title_desc')}  />
             </div>

          <h2 className="text-center pb-20 pt-20">
            <span className="text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('notes_title')}</span>
          </h2>
          <ul style={{ listStyle: 'none' }}>
            <li className="font-16">
              <i className="fa fa-angle-double-right" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '2', fontSize: '16px', color: 'red' }}></i>{getContent('notes_0_point')}
            </li>
            <li className="font-16">
              <i className="fa fa-angle-double-right" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '2', fontSize: '16px', color: 'red' }}></i> {getContent('notes_1_point')}
            </li>
            <li className="font-16 pb-50">
              <i className="fa fa-angle-double-right" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '2', fontSize: '16px', color: 'red' }}></i>{getContent('notes_2_point')}
            </li>
          </ul>
        </div>
      </section> */}
    </>)
}, "AppPricing");















