
import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
  const content = props?.server?.props?.contentdata;
  const getContent = (name) => props.getcontent('home' + (name ? `.${name}` : ''));
    return (<div >
    <section id="event" className="bg-white">
      <div className="container pt-40">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 pb-sm-10">
              <h3 className="title text-center mb-10 pb-20 font-28 mt-0 line-height-1">
                <span className=" text-uppercase text-bold text-theme-colored" style={{fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures_title')}</span>
              </h3>
              <p className="20" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px',}}>{getContent('keyfeatures_desc')}</p>
              <div
                data-wow-duration="1s"
                data-wow-delay="0.3s"
                className="col-sm-6 col-md-3 wow fadeInLeft animated"
                style={{
                  visibility: 'visible',
                  animationDuration: '1s',
                  animationDelay: '0.3s',
                }}
              >

                <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src="/files/secure/Key-icons---Copy.png?fileId=193&rd=40&asimg=1" alt="Key icons with lock symbols" ></img>
                  
                 
                  {/* <p className="font-20 text-center">
                    <strong className="theme-color" style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.0.title')}</strong></p> */}
                </div>
              </div>
              <div
                data-wow-duration="1s"
                data-wow-delay="0.3s"
                className="col-sm-6 col-md-3 wow fadeInLeft animated"
                style={{
                  visibility: 'visible',
                  animationDuration: '1s',
                  animationDelay: '0.3s',
                }}
              >
                <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src ="/files/secure/Key-icons---Copy-(7).png?fileId=190&rd=56&asimg=1" alt="Key icons with lock symbols"></img>
             
                
                   {/* <p className="font-20 text-center">
                    <strong className="theme-color"  style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.1.title')}</strong> 
                  </p> */}
                  </div></div>
              <div
                data-wow-duration="1s"
                data-wow-delay="0.3s"
                className="col-sm-6 col-md-3 wow fadeInLeft animated"
                style={{
                  visibility: 'visible',
                  animationDuration: '1s',
                  animationDelay: '0.3s',
                }}
              >
                <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src ="/files/secure/Key-icons---Copy-(6).png?fileId=187&rd=96&asimg=1" alt="Key icons with lock symbols"></img>
              
                    
                  {/* <p className="font-20 text-center">
                    <strong className="theme-color"  style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.2.title')}</strong></p> */}
                </div>
              </div>
              <div
                data-wow-duration="1s"
                data-wow-delay="0.3s"
                className="col-sm-6 col-md-3 wow fadeInLeft animated"
                style={{
                  visibility: 'visible',
                  animationDuration: '1s',
                  animationDelay: '0.3s',
                }}
              >
                <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src ="/files/secure/Key-icons---Copy-(5).png?fileId=188&rd=59&asimg=1" alt="Key icons with lock symbols"></img>
                  {/* <p className="font-20 text-center">
                    <strong className="theme-color"  style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.3.title')}</strong>
                  </p> */}
                </div>
              </div>
            </div>
            <div
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              className="col-sm-6 col-md-3 wow fadeInLeft animated"
              style={{
                visibility: 'visible',
                animationDuration: '1s',
                animationDelay: '0.3s',
              }}
            >
             <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src ="/files/secure/Key-icons---Copy-(4).png?fileId=191&rd=35&asimg=1" alt="Key icons with lock symbols"></img>
                  
                {/* <p className="font-20 text-center">
                  <strong className="theme-color"  style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.4.title')}</strong>
                </p> */}
              </div>
            </div>
            <div
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              className="col-sm-6 col-md-3 wow fadeInLeft animated"
              style={{
                visibility: 'visible',
                animationDuration: '1s',
                animationDelay: '0.3s',
              }}
            >
              <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src ="/files/secure/Key-icons---Copy-(3).png?fileId=189&rd=67&asimg=1" alt="Key icons with lock symbols"></img>
                  
                {/* <p className="font-20 text-center">
                  <strong className="theme-color"  style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.5.title')}</strong>
                </p> */}
              </div>
            </div>
            <div
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              className="col-sm-6 col-md-3 wow fadeInLeft animated"
              style={{
                visibility: 'visible',
                animationDuration: '1s',
                animationDelay: '0.3s',
              }}
            >
              <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src ="/files/secure/Key-icons---Copy-(2).png?fileId=192&rd=53&asimg=1" alt="Key icons with lock symbols"></img>
                 {/* <p className="font-20 text-center">
                  <strong className="theme-color"  style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.6.title')}</strong>
                </p> */}
              </div>
            </div>
            <div
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              className="col-sm-6 col-md-3 wow fadeInLeft animated"
              style={{
                visibility: 'visible',
                animationDuration: '1s',
                animationDelay: '0.3s',
              }}
            >
                <div className="icon-box text-center pl-0 pr-0 mb-0">
                <img src="/files/secure/Key-icons.png?fileId=194&rd=92&asimg=1" alt="Key icons with lock symbols"></img>
                    
                  {/* <p className="font-20 text-center">
                    <strong className="theme-color"  style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('keyfeatures.icon.7.title')}</strong></p> */}
                </div>
              </div>
              <div
                data-wow-duration="1s"
                data-wow-delay="0.3s"
                className="col-sm-6 col-md-3 wow fadeInLeft animated"
                style={{
                  visibility: 'visible',
                  animationDuration: '1s',
                  animationDelay: '0.3s',
                }}
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>);
}, "AppServices");
