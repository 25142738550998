const files = import.meta.glob('./**/*.jsx', { eager: true });
const fileskeys = Object.keys(files);
export const entries = {
    ...fileskeys.reduce((col,key)=>{
        const component = files[key].default;
        const name = key.filename();
        col[name] = component;
        globalThis[name] = component;
        // console.log({col,name,component});
        return col;
    },{})
}
console.log("partials",{files,entries});
export default entries;