import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import StripeContainer from "./StripeContainer";
import PaymentForm from "./PaymentForm";

import AppStripe from "./AppStripe";



export default constore(function () {
    return (
        <>
            <PaymentForm/> 
             <StripeContainer/>
            <AppStripe/>
            <AppFooter/>
        </>
    )
}, "stripe"); 