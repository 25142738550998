import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { constore } from './utils/mixins/mixin';
import './App.css';
import { useEffect } from 'react';

const AppWrapper = constore((props)=>{
  globalThis.$navigate = useNavigate();
  globalThis.$params = useParams();
  globalThis.$location = useLocation();
  return props.children;
})

function App(props) {
  useEffect(()=>{
    setupTemplateJS();
  },[]);
  return (
    <AppLayout>
      <AppWrapper>
        {props.children}
      </AppWrapper>
    </AppLayout>
  )
}

export default App
