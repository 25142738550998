import { constore } from "../utils/mixins/mixin";

export default constore(function (props) {
  return ( <section id="gallery" className="bg-lighter">
     <div className="container">
        <div className="section-title mb-10">
          <div className="row">
            <div className="col-md-12">
              <h2 className="mt-0 text-uppercase text-theme-colored title line-bottom line-height-1">Our<span className="text-theme-color-2 font-weight-400"> Gllery</span></h2>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-12">
           
              <div className="portfolio-filter font-alt align-center">
                <a href="#" className="active" data-filter="*">All</a>
                <a href="#select1" className="" data-filter=".select1">Photos</a>
                <a href="#select2" className="" data-filter=".select2">Campus</a>
                <a href="#select3" className="" data-filter=".select3">Students</a>
              </div>
              
              <div id="grid" className="gallery-isotope grid-4 gutter clearfix" style="position: relative; height: 495px;">
            
                <div className="gallery-item select1" style="position: absolute; left: 0px; top: 0px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/1.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/1.jpg">View more</a>
                  </div>
                </div>
                <div className="gallery-item select1" style="position: absolute; left: 285px; top: 0px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/2.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/2.jpg">View more</a>
                  </div>
                </div>

                <div className="gallery-item select2" style="position: absolute; left: 570px; top: 0px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/3.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/3.jpg">View more</a>
                  </div>
                </div>

                <div className="gallery-item select3" style="position: absolute; left: 855px; top: 0px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/4.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>

                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/4.jpg">View more</a>
                  </div>
                </div>

                <div className="gallery-item select1" style="position: absolute; left: 0px; top: 165px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/5.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/5.jpg">View more</a>
                  </div>
                </div>

                <div className="gallery-item select3" style="position: absolute; left: 285px; top: 165px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/6.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/6.jpg">View more</a>
                  </div>
                </div>

                <div className="gallery-item select2" style="position: absolute; left: 570px; top: 165px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/7.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/7.jpg">View more</a>
                  </div>
                </div>
        
                <div className="gallery-item select3" style="position: absolute; left: 855px; top: 165px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/8.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/8.jpg">View more</a>
                  </div>
                </div>
            
                <div className="gallery-item select1" style="position: absolute; left: 0px; top: 330px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/9.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/9.jpg">View more</a>
                  </div>
                </div>
            
                <div className="gallery-item select2" style="position: absolute; left: 285px; top: 330px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/10.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/10.jpg">View more</a>
                  </div>
                </div>
            
                <div className="gallery-item select1" style="position: absolute; left: 570px; top: 330px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/11.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/11.jpg">View more</a>
                  </div>
                </div>
            
                <div className="gallery-item select3" style="position: absolute; left: 855px; top: 330px;">
                  <div className="thumb">
                    <img className="img-fullwidth" src="http://placehold.it/280x160" alt="project"/>
                    <div className="overlay-shade"></div>
                    <div className="icons-holder">
                      <div className="icons-holder-inner">
                        <div className="styled-icons icon-sm icon-dark icon-circled icon-theme-colored">
                          <a data-lightbox="image" href="images/gallery/12.jpg"><i className="fa fa-plus"></i></a>
                          <a href="#"><i className="fa fa-link"></i></a>
                        </div>
                      </div>
                    </div>
                    <a className="hover-link" data-lightbox="image" href="images/gallery/12.jpg">View more</a>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> )
}, "AppGallery");