import { useEffect, useState } from "react";
import AppFilePicker from "./AppFilePicker";

function fetchOptions (props,setOptions) {
    let query = props.query || {};
    let params = getQuery(props);
    let hasQuery = !!Object.keys(query).length;
    let hasParams = !!Object.keys(params).length>=1;
    let canFetch = (hasQuery && hasParams) || !hasQuery;
    console.log("canFetch",canFetch,params,props.type,props.name,props.id,props.refers);
    if(props.refers&&canFetch) {
        let action  = props.referaction || "list";
        let select  = props.referselect || "id as key,name as label";
        let distinct = select.startsWith("distinct ");
        select = select.replaceAll(new RegExp('^distinct ','g') ,'');
        console.log("fetchOptions",params,props.id, props.services.apiCurd);
        props.services.apiCurd("Frontend",{
            tablename: props.refers,
            action,
            select,
            distinct,
            where:"by",
            ...params
        }).then(({data})=>{
            console.log("setOptions",data,props.id);
            props.onOptions && props.onOptions(data);
            const onOptionsTransform = props.onOptionsTransform || ((v)=>v);
            const refactoredOptions = onOptionsTransform(data);
            console.log("setOptions",data,refactoredOptions,props.id);
            setOptions(refactoredOptions);
        }).catch((err)=>{
            console.error(err);
        });
    }
}

function getQuery (props) {
    let query = props.query || {};
    console.log({getQuery:query});
    let params = Object.keys(query).reduce((c,key)=>{
        let value = props.appstore(query[key]);
        return {
            ...c,
            ...(value?{[key]:value}:{})
        }
    },{});
    return params;
}

function updateQuery(props,{query,setQuery}) {
    let newquery = getQuery(props);
    console.log({newquery,query});
    if(JSON.stringify(newquery) != JSON.stringify(query)) {
        setQuery(newquery);
    }
}

export default constore(function (props) {
    const [type] = useState(props.type || "text");
    const [query, setQuery] = useState(getQuery(props));
    const [options, setOptions] = useState(props.options || []);
    const choose = props.choose || "";
    const name = props.name || "";
    const required = props.required || false;
    const placeholder = props.placeholder || '';

    const onInvalid = (event)=>{
        props.invalidMessage && event.target.setCustomValidity(props.invalidMessage);
    }

    const handleEventChange = (event,key="value") => {
        event.target.setCustomValidity("");
        let value = event?.target[key]||'';
        let maxlen = event.target.dataset.maxlen;
        if(maxlen) {
            if((value+'').length>maxlen) {
                event.preventDefault();
            }
            value = value.slice(0,maxlen);
            event.target[key] = value;
        }
        if(props.storekey) {
            props.store(props.storekey,value);
        }
        if(props.onChange) {
            props.onChange(value,event,{
                options,
                option: options.find(o=>o.key==value)
            });
        }
        console.log({event});
    }


    useEffect(()=>{
        if(props.storekey) {
            if(props.appstore(props.storekey)) {
                props.store(props.storekey,'');
            }else if(props.defaultValue) {
                props.store(props.storekey,props.defaultValue);
            }
        }
    },[]);

    useEffect(()=>{
        if(props.storekey) {
            let updateValue = props.appstore(props.storekey);
            updateValue!=props.defaultValue && props.store(props.storekey,props.defaultValue);
        }
    },[props.defaultValue])

    useEffect(()=>{
        props.refers && fetchOptions(props,setOptions);
    },[props.refers,query]);

    useEffect(()=>{
        updateQuery(props,{query,setQuery});
    },[props.$store]);

    props.$setNode({
        props,
        options
    },props.name);

    
    let inputContainer = null;

    switch (type) {
        case "select":
            inputContainer = (
                <div class="form-group">
                    <div class="styled-select">
                        <select 
                            id={props.id || name} 
                            name={name}
                            class="form-control" 
                            onChange={(e)=>handleEventChange(e,"value")} 
                            required={required} 
                            placeholder={placeholder}>
                            <option value="" >Choose {choose}</option>
                            {
                                (options||[]).map(o => <option value={o.key} selected={o.key==props.defaultValue} >{o.label}</option>)
                            }
                        </select>
                    </div>
                </div>
            );
            break;
        case "textarea":
            inputContainer = (
                <div class="form-group">
                    <textarea 
                        required={required} 
                        placeholder={placeholder} 
                        rows="3" class="form-control required" 
                        id={name} name={name} 
                        aria-required="true" 
                        onChange={(e)=>handleEventChange(e,"value")} 
                        data-maxlen={props['data-maxlen']}
                        >
                    </textarea>
                </div>
            )
            break;
        case "checkbox":
            inputContainer = (
                <div class="form-group d-flex items-center justify-center gap-2">
                    <input type={type} id={name} name={name} required={required} placeholder={placeholder} class={Object.className({ '!w-auto': true, disabled:props?.disabled })} onChange={(e)=>handleEventChange(e,"value")} />
                    <span class="text-xs check-label">
                        {props.label && <span>{props.label}</span>}
                    </span>
                </div>
            )
            break;
        case "file":
            inputContainer = (
                <AppFilePicker {...props} />
            )
            break;
        default:
            inputContainer = (
                <div class={"form-group "+props.className} >
                    <input 
                        className={"form-control"} 
                        multiple={props.multiple} 
                        type={type} 
                        id={name} 
                        name={name} 
                        defaultValue={props.defaultValue} 
                        required={required} 
                        placeholder={placeholder} 
                        class="form-control" 
                        onChange={(e)=>handleEventChange(e,"value")}
                        min={props.min}
                        max={props.max}
                        pattern={props.pattern}
                        onInvalid={onInvalid}
                    />
                    {
                        props.info && <span className="form-text small text-muted">{props.info}</span>
                    }
                </div>
            );
    }
    return (
        <>
            <div className="text-left" style={{marginBottom:'5px'}}>
                {!["checkbox"].includes(type) && props.label && <span>{props.label}</span>}
            </div>
            {inputContainer}
        </>
    )         
}, 'AppInput');