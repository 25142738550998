import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppPricing from "../../components/AppPricing";
export default constore(function () {
    return (
        <>
            <AppPricing/>
            <AppFooter/>
            </>
    )
}, "AppPricing");