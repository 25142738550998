import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppLogin from "../../components/AppLogin";
import "./login.css";
// import AppLoginPopup from "../../components/AppLoginPopup";

export default constore(function () {
    return (
        <>
         {/* <AppLoginPopup/>  */}
            <AppLogin/> 
            <AppFooter/>
        </>
    )
}, "AppLogin");