import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppPhotoRetouchDescription from "../../components/AppPhotoRetouchDescription";

export default constore(function () {
    return (
        <>
            <AppPhotoRetouchDescription/>
            <AppFooter/>
        </>
    )
}, "photoretouchdescription");


