import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppContact from "./AppContact";
export default constore(function () {
    return (
        <>
            <AppContact/>
            <AppFooter/>
        </>
    )
}, "contact-us");