import React from "react";
import { constore } from "../utils/mixins/mixin";

const handleSubmit = (e, props) => {
    let data = {};
    if (typeof e == "string") {
        data = document.querySelector("#" + e).getData();
    } else {
        e.preventDefault();
        data = e.target.getData();
    }
    props.services
        .apiCurd("Frontend", {
            action: "login",
            ...data,
        })
        .then((respose) => {
            if (props.close) {
                props.close("ok");
                if(localStorage.getItem("redirect_path")) {
                    location.href = localStorage.getItem("redirect_path");
                }
            } else {
                if (e?.target?.reset) {
                    e.target.reset();
                }
                if(localStorage.getItem("redirect_path")) {
                    location.href = localStorage.getItem("redirect_path");
                } else {
                    location.href = props.buildpath("/");
                }
            }
        });
};

const ALink = (props) =>
    props.type == "a" ? (
        <a {...props}>{props.children}</a>
    ) : (
        <a
            onClick={() => props.$trigger(props.triggername)}
            {...props}
            type="button"
            className="  float-left text-primary  text-decoration-underline "
        >
            {props.children}
        </a>
    );

const AppLogin = (props) => {
    let formAlert = props.from == "alert";
    let linkType = formAlert ? "button" : "a";
    const userEmail = props.server?.user?.email;
    const getContent = (name) => props.getcontent('home.login' + (name ? `.${name}` : ''))
    return (
        <div className="bg-lighter">
            <div className="container mt-5">
                <div className="row">
                    <div className=" card p-50 bg-white rounded !m-auto">
                        <div className="col-md-6 col-md-offset-3">
                            <h1 className="text-center sunflower sans-serif text-theme font-36"
                                style={{fontFamily: "Geneva, Verdana, sans-serif"}}>{getContent('login_title')}
                            </h1>
                            <form
                                id="login_form"
                                name="login_form"
                                // value="Login Here.."
                                method="post"
                                {...(formAlert
                                    ? {
                                          action: "javascript:void(0)",
                                      }
                                    : {})}
                                onSubmit={(e) => handleSubmit(e, props)}
                            >
                                <div className="form-group  pt-15 ">
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <i className="glyphicon glyphicon-envelope"></i>
                                        </span>
                                        <AppInput
                                            type="email"
                                            name="email"
                                            placeholder="Enter Your Email Address "
                                            required={true}
                                            defaultValue={userEmail}
                                        ></AppInput>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <i className="glyphicon glyphicon-lock"></i>
                                        </span>

                                        <AppInput
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="Enter your password"
                                            required
                                            style={{
                                                fontFamily:
                                                    "Geneva, Verdana, sans-serif",
                                            }}
                                        />
                                    </div>
                                </div>

                                <button
                                    type={formAlert ? "button" : "submit"}
                                    onClick={() =>
                                        handleSubmit("login_form", props)
                                    }
                                    className="btn btn-theme-colored btn-block"
                                    style={{
                                        fontFamily:
                                            "Geneva, Verdana, sans-serif",
                                    }}
                                >
                                    Login
                                </button>
                                <br />
                                <div style={{ marginBottom: "10px" }}>
                                    <ALink type={linkType} href="/registration" hasCountry={false} className="float-left text-primary font-16"
                                        style={{textDecoration: "underline",fontFamily:"Geneva, Verdana, sans-serif"}}>{getContent('login_create_new_account')}
                                    </ALink>
                                </div>
                                <div>
                                    <ALink type={linkType} href="/forgotpassword" hasCountry={false} className="forgot-link float-right text-primary font-16"
                                        style={{textDecoration: "underline",fontFamily:"Geneva, Verdana, sans-serif",}}>{getContent('login_forgot_password')}  
                                    </ALink>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default constore(AppLogin);
