import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppRefundPolicy from "../../components/AppRefundPolicy";

export default constore(function () {
    return (
        <>
            <AppRefundPolicy/>
            <AppFooter/>
        </>
    )
}, "refund-policy");