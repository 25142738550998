import React from "react";
import { constore } from "../utils/mixins/mixin";

export default constore(function (props) {
  const content = props?.server?.props?.contentdata;
  console.log({ content });
  const getContent = (name) => props.getcontent('services' + (name ? `.${name}` : ''));
  const isMobile = document.body.clientWidth <= 900; 

  return (
    <div>
      <section id="home">
        <div className="divider parallax">
          <img
            className='img-fluid'
            src={`/files/secure/professional-photo-editing-services.jpg?fileId=220&rd=82&asimg=1&mobile=${isMobile ? '1' : '0'}`}
            style={{
              backgroundPosition: 'center',
              objectFit: 'cover',
              width: '100%',
              height: 'auto',
            }}
            alt="Parallax Image"
          />
        </div>
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container slider-size">
              <div className="col-md-12 text-center">
                <div className="pt-0 pb-0"></div>
              </div>
            </div>
            <div
              className="border-1px "
              style={{
                backgroundColor: '#C0C0C0',
                fontFamily: 'Geneva, Verdana, sans-serif',
                textAlign: 'justify',
                fontWeight: 'bold',
              }}
            >
             <h1 className="pt-0 text-theme-color-2 text-center font-28 font-md-20" style={{ margin: '9px', fontSize: 'inherit', fontWeight: 'inherit' }}>
            {getContent('title_headline')}
            </h1>  
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}, "AppServicesTitleHeadline");
