import React, { useState } from 'react';
import { constore } from '../utils/mixins/mixin';

const FaqSection = constore((props) => {
    const viewData = (props.data||"[]").parse();
    const getContent = (name) => {
      if(Object.keys(viewData).length) {
        return Object.getNested(viewData,"faq"+(name ? `.${name}` : ''));
      } else {
        return props.getcontent(`home.faq` + (name ? `.${name}` : ''))
      }
    };
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFaq = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const isMobile = window.innerWidth <= 767;
    
    const faqs = [
        {
            question: getContent('question.1.value'),
            answer: getContent('answer.1.value')
        },
        {
            question: getContent('question.2.value'),
            answer: getContent('answer.2.value')
        },
        {
            question: getContent('question.3.value'),
            answer: getContent('answer.3.value')
        },
        {
            question: getContent('question.4.value'),
            answer: getContent('answer.4.value')
        },
        {
            question: getContent('question.5.value'),
            answer: getContent('answer.5.value')
        },
        {
            question: getContent('question.6.value'),
            answer: getContent('answer.6.value')
        }
    ];
    const $node = {
        props,
        viewData,
        isMobile,
        faqs
    };
    props.$setNode($node);
    return (
        <div style={{ backgroundColor: 'rgb(5 27 46)', minHeight: '65vh', padding: '3vh 0'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
                            <h2 style={{ fontWeight: 'bold', position: 'relative', display: 'inline-block', color: '#e79951' }}>
                            {getContent('title')}
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-7">
                        {faqs.map((faq, index) => (
                            <div key={index} style={{ marginBottom: '10px' }}>
                                <div
                                    style={{
                                        backgroundColor: activeIndex === index ? '#e79951' : '#fff',
                                        padding: '15px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        color: '#000',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        fontSize: '16px',
                                        fontFamily: 'Geneva, Verdana, sans-serif'
                                    }}
                                    onClick={() => toggleFaq(index)}
                                >
                                    <span>{index + 1}. {faq.question}</span>
                                    <span>{activeIndex === index ? '-' : '+'}</span>
                                </div>
                                {activeIndex === index && (
                                    <div style={{
                                        padding: '15px',
                                        backgroundColor: '#fff',
                                        borderRadius: '5px',
                                        color: '#333',
                                        marginTop: '3px',
                                        fontSize: '14px',
                                        fontFamily: 'Geneva, Verdana, sans-serif'
                                    }}>
                                        {faq.answer}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="col-md-5">
                        <img
                            src="/files/secure/faq-image11.png?fileId=230&rd=97&asimg=1" 
                            alt="FAQ Section"
                            style={{
                                height: 'auto',
                                borderRadius: '5px',
                                marginTop: isMobile ?'10px' :'20px',
                                // width: '80%',
                                // paddingTop: isMobile ? '20px' : '',
                                // padding: isMobile ? '90px 0px 0px 10px':'',
                                // marginLeft:isMobile ? '45px':'',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
},"FaqSection");

export default FaqSection;
