import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import mixins from "./utils/mixins/mixin";
mixins.setInitials();
import router from "./routes";
import { store } from "./utils/redux/store";
import { Provider } from "react-redux";
import Partials from "./components/partials";
import imagepaths from "./imagepaths";

window.mixins = mixins;

const container = document.getElementById("approot");
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<RouterProvider router={router} />
	</Provider>
);

setTimeout(() => {
	Object.entries(imagepaths).map(([key, val]) => {
		document.body.style.setProperty(`--Image-${key.snakeCase()}`, `url(${val})`);
	});
});