import React, { useEffect, useState } from "react";
import { constore, createStyled } from "../../utils/mixins/mixin";
import AppServicesMegamenu from "../AppServicesMegamenu";
import AppLogin from "../AppLogin";
import AppRegistrationForm from "../AppRegistrationForm";
import AppResetPassword from "../AppResetPassword";
import AppSearchLocation from '../../pages/searchlocation/AppSearchLocation';
import AppCustomerDetails from "../../pages/customer-details/AppCustomerDetails";
import AppFreeRetouchedImage from "../AppFreeRetouchedImage";
// import AppCartPopUp from '../../pages/cart/AppCartPopUp';

const NavBrand = createStyled(
    (styled) => styled.a`
        margin: auto;
        margin-left: 0px;
        & > img {
            width: 175px;
            border-radius: 6px;
        }
    `,
    (Component) => (props) =>
        <Component className={props.className} href={props.href} >{props.children}</Component>
);

const Header = createStyled(
    (styled) => styled.header`
        & nav.menuzord {
            display: flex;
            justify-content: space-between; 
            align-items: center; 
        }
    `,
    (Component) => (props) =>
        <Component className={props.className}>{props.children}</Component>
);

export default constore(function (props) {
    const isActivePath = (path) => {
        return path=="/"?globalThis.location.pathname==path:globalThis.location.pathname.includes(path);
    };
    const getUserTag = () =>
        props.server.user?.name
            .split(" ")
            .slice(0, 2)
            .reduce((c, v) => c + v[0], "");
    useEffect(() => {
        props.$onTrigger("loginModel", () => {
            localStorage.setItem("redirect_path",location.href);
            let probj = alert(
                ({ close }) => {
                    const [componentName, setComponentName] = useState("login");
                    const onNavigate = (destination) => {
                        setComponentName(destination);
                    };
                    return (
                        <div style={{ position: "relative" }}>
                            <i
                                className="fa fa-close"
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    padding: "5px",
                                    background: "whitesmoke",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    zIndex: 1,
                                }}
                                onClick={() => close("cancel")}
                            ></i>
                            {componentName === "login" && (
                                <AppLogin
                                    close={close}
                                    from="alert"
                                    onNavigate={onNavigate}
                                />
                            )}
                            {componentName === "registration" && (
                                <AppRegistrationForm />
                            )}

                            {componentName === "resetPassword" && (
                                <AppResetPassword />
                            )}
                        </div>
                    );
                },
                { hasFooter: false }
            );
            probj.promise
                .then(() => {
                    props.$trigger("userLoggedIn");
                })
                .catch(() => {
                    // props.trigger("userLoggedIn");
                });
        });
        if (!sessionStorage.getItem('hasVisited')) {
            document.querySelector("#flashuploadbtn").click();
            sessionStorage.setItem('hasVisited', 'true');
        }
    }, []);


    props.$setNode({
        props,
    });
    return (
        <>
        <AppFreeRetouchedImage  needRequest={true} payload={{"action":"create",tablename:"enquiry", "enquiry_type":"free_image_request"}}/>
        <Header id="header" className="header">
            <div className="header-nav">
                <div className="header-nav-wrapper navbar-scrolltofixed bg-theme-colored border-bottom-theme-color-2-1px">
                    <div className="container">
                        <nav
                            id="menuzord"
                            className="menuzord bg-theme-colored pull-left flip menuzord-responsive d-flex"
                        >
                            <NavBrand className="pull-right logo-container" href={props.buildpath("/")}>
                                <img className="logo-size" src="/files/secure/Logo.jpg?fileId=222&rd=1&asimg=1" alt="logo" style={{ width: ' 150px', height: '58px', borderRadius: '10px' }} />
                            </NavBrand>
                            <ul className="menuzord-menu">
                                <li className="menulabel" style={{ textAlign: 'center', paddingBottom:'10px'}}>MENU</li>
                                {/* <i 
                                className="fa fa-close" 
                                 style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    padding: "5px",
                                    background: "whitesmoke",
                                   
                                    cursor: "pointer",
                                    zIndex: 1,
                                }}
                                onClick={() => close("cancel")} >
                                </i> */}
                                <li className={Object.className({
                                    active: isActivePath("/")
                                })}><a href={props.buildpath("")}>Home</a></li>
                                   <AppServicesMegamenu/>  
                                <li className={Object.className({
                                    active: isActivePath("/about-us")
                                })}><a href={'/'+props.buildpath("/about-us",{hasCountry:false})}>About</a></li>
                                <li className={Object.className({
                                    active: isActivePath("/pricing")
                                })}><a href={props.buildpath("/pricing")}>Pricing</a></li>
                                <li className={Object.className({
                                    active: isActivePath("/contact-us")
                                })}><a href={'/'+props.buildpath("/contact-us",{hasCountry:false})}>Contact Us</a></li>
                                <li className={Object.className({
                                    active: isActivePath("/uploadphotos")
                                })}><a href={'/'+props.buildpath("/uploadphotos",{hasCountry:false})}>Upload Photos</a></li>
                                {/* <li className={Object.className({
                                    active: isActivePath("/blog")
                                })}><a href={'/'+props.buildpath("/blog",{hasCountry:false})}>Blog</a></li> */}
                                <li className={Object.className({
                                            active: isActivePath("/blog")
                                })}><a href={'/'+props.buildpath("/blog",{hasCountry:false})}>Blog</a></li>
                                {
                                    !props.server.user && <>
                                        <li className={Object.className({
                                            active: isActivePath("/guestuser")
                                        })}><a href={'/'+props.buildpath("/guestuser",{hasCountry:false})}>Guest User</a></li>
                                        <li className={Object.className({
                                            active: isActivePath("/login")
                                        })}><a href={'/'+props.buildpath("/login",{hasCountry:false})}>Login</a></li>
                                    </>
                                }
                                <AppSearchLocation />

                                {props.server.user && (
                                    <>
                                        <li className={Object.className({
                                            "user-tag": true,
                                        })}>
                                            <a className="tag" href={props.buildpath("/customer-details",{hasCountry:false})} >
                                                {getUserTag()}
                                            </a>
                                            <a href={props.buildpath("/order-summary",{hasCountry:false})} title="Checkout" >
                                                <i className="fa fa-shopping-cart"></i>
                                            </a>
                                            <a href={"/logout"} title="Logout" >
                                                <i className="fa fa-sign-out"></i>
                                            </a>
                                        </li>
                                    </>
                                )}
                            </ul>
                            <div id="top-search-bar" className="collapse">
                                <div className="container"></div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </Header> 
        </>   
    );
}, "AppHeader");
