import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
    return (
        <>
            <section id="home">
                <div className="maximage-slider responsive-banner">
                    <div id="maximage" className="mc-cycle" style={{ height: '919px', width: '1920px' }}>
                        <div className="mc-image" title="" style={{ backgroundImage: 'url("/files/secure/Portrait-retouching.jpg?fileId=47&rd=94&asimg=1")', height: '919px', width: '1920px' }} data-href=""></div>
                    </div>
                </div>
                <div className="display-table">
                    <div className="display-table-cell">
                        <div className="slider-size">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="pt-50 pb-50"></div>
                                </div>
                            </div>
                        </div>
                        <div className="border-1px slider-head">
                            <h3 className="mt-0 pt-5 text-white text-center slider-head-font">Professional Photo Editing Service for 360 Degree Photographers</h3>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container pt-50 ">
                <div className="section-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="meet-doctors">
                                <h1 className="mt-0 font-29 text-center pb-20">
                                    <span className="text-theme-color-2">Portrait Retouching Services </span></h1>
                                <div style={{ textAlign: 'justify' }}>
                                    <p>Flawless images are always hard to capture in the first attempt without imperfections and other undesired characteristics which may
                                        affect the overall quality and appealing of these images. Ensuring vibrancy and flawlessness in images ColourCubz's indispensable
                                        support will enhance the appeal of your portrait images. Incorporating high-end photoshop portrait retouching techniques, our experts
                                        will remove unwanted spots from a picture. Portrait photos are aimed at the identification and preservation of unique outlines and
                                        expressions of individual faces. </p>
                                    <p>Equipped with an expert team of workforce, timely updated photo retouching infrastructure,
                                        and high-end techniques, we always deliver appropriate perspectives in every portrait image. Attending even to the minute details,
                                        our experts will utilize appropriate skills &amp; photo editing tools for enhancement of minute details of portrait images in the
                                        best possible light. While attending to each and every portraits, we also strive to use result-oriented tools such as Adobe Photoshop
                                        and Photomatix for HDR images, etc. Well-focused on the achievement of project requirements of each client, our offerings have
                                        benefited numerous clients across the world.</p>
                                    <p>With a decade of experience in the development of collages, composition, color &amp; density correction, contrast &amp; saturation
                                        adjustment, color balancing, background replacement/removal and much more. We also offer assistance in operations such as addition
                                        or removal of scars, wrinkles, blemishes, digital make-up, glare &amp; red-eye, untidy hair or fine structures, motion &amp;
                                        reflection effects, etc.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 blog-pull-right mob-hide">
                            <div className="row">
                                <h2 className="mt-0 line-height-1 font-28 text-center pb-50">
                                    <span className="text-theme-color-2"> Our Portrait Retouching Services</span></h2>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s2.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div>
                                                    <p className="font-14 text-uppercase"><strong> Wedding Portrait Retouching</strong></p>
                                                    <p className="font-11">Effective manipulation of all minor mistakes occurred with bridal...</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s3.jpg" className="sub-width" /></div></div>
                                            <div className="col-md-7 ">
                                                <div><p className="font-14 text-uppercase"><strong> Baby Portrait Retouching</strong></p>
                                                    <p className="font-11">Retouching of baby portraits by adjustment of depth, detailing, c...</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s4.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div><p className="font-14 text-uppercase">
                                                    <strong> Couple Portrait Retouching</strong></p>
                                                    <p className="font-11">Editing maximum excellence and sense of romanticism into your cou...</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s1.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div><p className="font-14 text-uppercase">
                                                    <strong> Pregnancy Portrait Retouching</strong></p>
                                                    <p className="font-11">Manipulation of maternity photographs by removal of all imperfect...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s2.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div>
                                                    <p className="font-14 text-uppercase">
                                                        <strong> Fashion Portrait Retouching</strong></p>
                                                    <p className="font-11">Enhancement of fashion images by efficient portrait retouching, c...</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s3.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div>
                                                    <p className="font-14 text-uppercase">
                                                        <strong> Corporate Portrait Retouching</strong></p>
                                                    <p className="font-11">Manipulation of business portraits with the aim of effective mark...</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s1.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 "><div>
                                                <p className="font-14 text-uppercase">
                                                    <strong> Black &amp; White Portrait  </strong></p>
                                                <p className="font-11">Improvement of black and white portrait images by addition/remova...</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s1.jpg" className="sub-width" /></div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div>
                                                    <p className="font-14 text-uppercase">
                                                        <strong> Family Portrait Retouching</strong></p>
                                                    <p className="font-11">Basic and advanced image enhancement of family portraits with eff...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-20">
                                    <div className="service-block bg-white">
                                        <div className="col-md-12 ">
                                            <div className="col-md-5">
                                                <div className="thumb ">
                                                    <img alt="featured project" src="/images/services/s4.jpg" className="sub-width" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div>
                                                    <p className="font-14 text-uppercase">
                                                        <strong> Headshot Portrait Retouching</strong></p>
                                                    <p className="font-11">Best portrayal of fashion &amp; business images by adjustment/removal...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-lighter">
                <h3 className="text-center city-head-font pb-30 pt-20 text-theme-colored"> Our Pricing Packages for Product Photo Editing Services </h3>
                <div className="section-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                                <div className="pricing-table maxwidth400">
                                    <div className="font-16 pl-20 text-center pr-20 p-10" style={{ backgroundColor: 'rgb(255, 0, 0)' }}>
                                        <strong className="text-white font-20 text-center">Basic</strong></div>
                                    <img src="/images/colorcubz/pricing/p1.jpg" alt="" />
                                    <div className=" bg-white border-1px p-30 pt-20 pb-30">
                                        <p className="font-28 m-0 text-center text-black">
                                            <span className="text-theme-color-2">$ </span>0.5</p>
                                        <p className="text-center font-18 ">Per Image</p>
                                        <ul className="list check theme-colored pb-0" style={{ height: '460px' }}>
                                            <li>LR Adjustments</li>
                                            <li>Basic Color Fixing</li>
                                            <li>White Balance</li>
                                            <li>Exposure</li>
                                            <li>Sharpening</li>
                                            <li>Contrast and Light Adjustments</li>
                                            <li>Removing Color Cast</li>
                                            <li>Perspective Corrections</li>
                                            <li>Cropping or Resizing</li>
                                            <li>PS Adjustments</li>
                                            <li>Slight Dust Removal</li>
                                            <li>Sky Color Enhancement</li>
                                        </ul>
                                    </div>
                                    <a href="https://www.colourcubz.com/order-summary" className="btn btn-lg btn-theme-colored  btn-block pt-15 pb-15 btn-flat">
                                        <span className="btn btn-colored btn-theme-color-2 text-uppercase text-white">Buy Now</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                                <div className="pricing-table maxwidth400">
                                    <div className="font-16 pl-20  text-center pr-20 p-10" style={{ backgroundColor: 'rgb(76, 175, 80)' }}>
                                        <strong className="text-white font-20 text-center">Pro</strong></div>
                                    <img src="/images/colorcubz/pricing/p2.jpg" alt="" />
                                    <div className=" bg-white border-1px p-30 pt-20 pb-30">
                                        <p className="font-28 m-0 text-center text-black">
                                            <span className="text-theme-color-2">$ </span>0.7</p>
                                        <p className="text-center font-18 ">Per Image</p>
                                        <ul className="list check theme-colored pb-0" style={{ height: '460px' }}>
                                            <li>Basic Corrections</li>
                                            <li>Lawn Retouch</li>
                                            <li>Drawing Fire and Adding it to Fireplaces</li>
                                            <li>Outdoor Sky Replacement</li>
                                            <li>Fixing Burnt Out Lightbulbs</li>
                                            <li>’Blueing’ Pools, Lakes, Rivers, Oceans</li>
                                            <li>Small Flash Reflection/ camera Removal</li>
                                            <li>Dust &amp; Garbage Edit</li>
                                            <li>Retouching and Masking Together Two Images/Exposures</li>
                                            <li>Small Objects Removal</li>
                                            <li>Clarity Noise &amp; Blur Reduction</li>
                                            <li>HDR Photo Editing</li>
                                            <li>Vertical &amp; Horizontal Straightening</li>
                                            <li>Windows Enhancement</li>
                                            <li>Cord Removal</li>
                                            <li>TV Screen Replacement</li>
                                        </ul>
                                    </div>
                                    <a href="https://www.colourcubz.com/order-summary" className="btn btn-lg btn-theme-colored  btn-block pt-15 pb-15 btn-flat">
                                        <span className="btn btn-colored btn-theme-color-2 text-uppercase text-white">Buy Now</span></a>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                                <div className="pricing-table maxwidth400">
                                    <div className="font-16 pl-20  text-center pr-20 p-10" style={{ backgroundColor: 'rgb(255, 0, 0)' }}>
                                        <strong className="text-white font-20 text-center">Premium</strong></div>
                                    <img src="/images/colorcubz/pricing/p3.jpg" alt="" />
                                    <div className=" bg-white border-1px p-30 pt-20 pb-30">
                                        <p className="font-28 m-0 text-center text-black">
                                            <span className="text-theme-color-2">$ </span>1.0</p>
                                        <p className="text-center font-18">Per Image</p>
                                        <ul className="list check theme-colored pb-0" style={{ height: '460px' }}>
                                            <li>Professional Corrections</li>
                                            <li>Editing and Masking Together 3-5 Images/Exposures</li>
                                            <li>Flash Shadow Removal</li>
                                            <li>Interior Sky and Grass Replacements</li>
                                            <li>Wall/Floor/Ground Cleaning</li>
                                            <li>Medium Objects Removal</li>
                                            <li>Carpets/Curtains/Bedcovers Smoothing</li></ul></div>
                                    <a href="https://www.colourcubz.com/order-summary" className="btn btn-lg btn-theme-colored  btn-block pt-15 pb-15 btn-flat">
                                        <span className="btn btn-colored btn-theme-color-2 text-uppercase text-white">Buy Now</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}, "App360DegreeEditingServices");
