import { useEffect } from "react";
import { useState } from "react";
import { constore } from "../../utils/mixins/mixin";

const handleSubmit = (e, props) => {
    e.preventDefault();
    let data = e.target.getData();
    props.services.apiCurd("Frontend", {
        tablename: "enquiry",
        action: "create",
        browser_fingerprint: props.services.getBrowserFingerprint().hash(),
        ...data,
        ...(props.payload || {}),
        enquiry_type: "contact"
    }).then(({ data }) => {
        e.target.reset();
        alert("Thank you for Contacting with us!");
    });
}

export default constore((props) => {
    const country_code = props.getProps("country.code");
    const [phoneCode, setPhoneCode] = useState(props.getProps("country.phone_code"));
    const userName = props.server?.user?.name;
    const userEmail = props.server?.user?.email;

    const onOptionsTransform = (data) => {
        let countryName = props.getProps("country.name") || "";
        data = data.map(item => {
            item.label = item.label
                .split("{incountry}").join(`${countryName}`)
                .split("{country}").join(`in ${countryName}`)
            return item;
        });
        console.log("onOptionsTransform", { data, countryName })
        return data;
    }


    const handleCountryChange = (value, event, { option }) => {
        setPhoneCode(option.phone_code);
    };

    useEffect(() => {
        props.store('request.searchable', 1);
        props.store('request.country_code', country_code);
    }, []);

    return (
        <form id="reservation_form" name="reservation_form" onSubmit={(e) => handleSubmit(e, props)} >
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group  mb-10 ">
                        <AppInput name="name" placeholder="Enter Name*" required={true} defaultValue={userName} ></AppInput>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group mb-10">
                        <AppInput  type="email" name="email" placeholder="Enter Your Email Address* " required={true} defaultValue={userEmail} ></AppInput>
                    </div>
                </div>

   
                <div className="col-sm-6">

                    <AppInput
                        type="select"
                        choose="Country"
                        refers="countries"
                        referselect="distinct code as key,name as label,phone_code"
                        name="country"
                        placeholder="Enter Country"
                        defaultValue={country_code}
                        onChange={(...args) => handleCountryChange(...args)}
                        storekey="request.country_code"
                        required={true}
                    />
                </div>


                {/* <div className="col-sm-6">
                    <AppInput
                        type="select"
                        choose="State"
                        name="state"
                        referselect="distinct code as key,name as label"
                        placeholder="Enter State"
                        query={{
                            country_code: "request.country_code"
                        }}
                        refers="states"
                        storekey="request.state_code"
                        required={true}
                    >
                    </AppInput> </div> */}

                <div className="col-sm-6">
                    <AppInput
                        type="select"
                        choose="City"
                        name="city"
                        placeholder="Enter City"
                        query={{
                            country_code: "request.country_code",
                            searchable: "request.searchable"
                        }}
                        refers="cities"
                        storekey="request.city"
                        required={true}
                    >
                    </AppInput>
                </div>

                <div className="col-sm-12">
                    <div className="form-group mb-10">
                        <AppInput
                            type="text"
                            name="phone"
                            pattern="\+\d{1,3}\d{7,10}"
                            placeholder="Enter Phone No*"
                            invalidMessage={"Please enter valid mobile number"}
                            {...(phoneCode ? { defaultValue: '+' + phoneCode } : {})}
                            required>
                        </AppInput>
                    </div>
                </div>

                <div className="col-sm-12">
                    <AppInput
                        type="select"
                        choose="Service"
                        name="service_id"
                        refers="services"
                        referselect="id as key,title as label"
                        placeholder="Select Service"
                        onOptionsTransform={onOptionsTransform}
                        required={true}
                    >
                    </AppInput>
                </div>

   

                <div className="col-sm-12">
                    <div className="form-group mb-10 ">
                        <AppInput type="text" name="company_name" placeholder="Enter Company Name" ></AppInput>
                    </div>
                </div> <div className="col-sm-12">
                    <div className="form-group mb-10 ">
                        <AppInput type="text" name="company_website" placeholder="Enter Company Website" ></AppInput>
                    </div>
                </div>
                <div className="col-sm-12">
                    <AppInput
                        type="textarea"
                        name="message"
                        data-maxlen="200"
                        placeholder="Enter Message(200 words limit)"
                        required={true}
                    >
                    </AppInput>
                </div>

                <div className="col-sm-12">
                    <AppReCaptcha />
                </div>

                <div className="col-sm-12">
                    <div className="form-group mb-0 text-center pt-30">
                        <button type="submit" id="real_file" data-loading-text="Please wait..."
                            className="btn btn-colored btn-theme-colored text-white btn-lg enquire-now-button">Submit</button>
                        <AppInput type="hidden" name="_token" value="RCz65wgMH6BpSdCWMGg1Exh0hwgNo3mKPc8UwKbU" />
                    </div>
                </div>
            </div>
        </form>
    )
}, 'AppContactRegister')