import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppAbout from "../../components/AppAbout";


export default constore(function () {
    return (
        <>
            <AppAbout/>
            <AppFooter/>
        </>
    )
}, "about");