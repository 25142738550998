import axios from "axios";
import endpoints from "./endpoints";
const environment = Object.entries(endpoints).map(([key,val])=>({...val,name:key})).find(obj=>(obj?.base?.split(',')||[]).includes(window?.location?.origin))?.name||'dev';
export const api = endpoints[environment];
axios.defaults.headers.common["Authorization"] = endpoints.static_token;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL = endpoints.api_base;
window.axios = axios;
window.endpoints = endpoints;
window.environment = environment;
export default api;