import React, { useState } from "react";
import { constore } from '../../utils/mixins/mixin';
import AppContactRegister from "./AppContactRegister";

// export const Pragraph = (props)=>{
//   let [readMore, setReadMore] = useState(false);
//   let content = props.content || props.children;
//   let parr = content.split('\n').filter(v => v);
//   const isMobile = document.body.clientWidth <= 900;
//   return <>
//       {
//           parr.map((v, i, arr) => {
//               if (isMobile && i == 0 && arr.length>1) {
//                   if (!readMore) {
//                       return <p>
//                           {v}
//                           <span className={`ml-auto mob-view ${isMobile && !readMore ? 'show-read-more' : 'hide-read-more'}`} onClick={() => setReadMore(true)}>
//                               read more..
//                           </span>
//                       </p>
//                   } else {
//                       return <p>{v}</p>;
//                   }
//               } else if (!isMobile || readMore || arr.length==1) {
//                   return <p>{v}</p>
//               }
//           })
//       }
//   </>
// }
const AppContact = constore(function (props) {
  // let [readMore, setReadMore] = useState(false);
  const content = props?.server?.props?.contentdata;
  console.log({ content });
  const getContent = (name) => props.getcontent('contact' + (name ? `.${name}` : ''));
  const isMobile = document.body.clientWidth <= 900; 
  
  return (
    <div className="main-content">
      <section>
        <img alt="contact_us_image" src={`/files/secure/contact-us.jpg?fileId=219&rd=96&asimg=1&mobile=${isMobile ? '1' : '0'}`} className='w-full'></img> 
      </section>
      <section className="divider">
        <div className="container">
          <h1 className="text-center text-theme-colored" style={{ textAlign: 'center', fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('title')}</h1>
          <div style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px', }}>
           {getContent('title_desc')}
          </div>
          <div className="row pt-30">
            <div className="col-md-4" style={{ marginTop: "125px" }}>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="icon-box left media bg-deep p-15 mb-20">
                    <a href="#" className="media-left pull-left"><i className="pe-7s-map-2 text-theme-colored"></i></a>
                    <div className="media-body">
                      <strong className="text-theme-colored" style={{ textAlign: 'justify',fontFamily: 'Geneva, Verdana, sans-serif' }}> {getContent('sidepad.3.title')}</strong>
                      <p style={{fontFamily: 'Geneva, Verdana, sans-serif' }}> {getContent('sidepad.3.value')}</p>
                    </div>
                  </div>
                  <div className="icon-box left media bg-deep p-15 mb-20">
                    <a href="#" className="media-left pull-left"><i className="pe-7s-map-2 text-theme-colored"></i></a>
                    <div className="media-body">
                      <strong className="text-theme-colored" style={{ textAlign: 'justify',fontFamily: 'Geneva, Verdana, sans-serif' }}> {getContent('sidepad.0.title')}</strong>
                      <p style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif' }}> {getContent('sidepad.0.value')}</p>
                    </div>
                  </div>
                  
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <div className="icon-box left media bg-deep p-5 mb-10">
                    <a href="#" className="media-left pull-left"><i className="pe-7s-call text-theme-colored"></i></a>
                    <div className="media-body">
                      <strong className="text-theme-colored" style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif', marginBottom: "20px", }}>{getContent('sidepad.1.title')}</strong>
                      <p style={{ marginLeft: "10px", fontFamily: 'Geneva, Verdana, sans-serif' }}>&nbsp;&nbsp;{getContent('sidepad.1_0.value')}</p>
                      <p style={{ marginLeft: "10px", fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('sidepad.1.value')}</p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <div className="icon-box left media bg-deep p-15 mb-20">
                    <a href="#" className="media-left pull-left"><i className="pe-7s-mail text-theme-colored"></i></a>
                    <div className="media-body">
                      <strong className="text-theme-colored" style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('sidepad.2.title')}</strong>
                      <p style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('sidepad.2.value')}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-8">
              <h2 className="line-bottom mt-0 mb-20 text-theme-colored" style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('form_label')}</h2>
              <AppContactRegister/>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.337139211885!2d77.64393778439464!3d13.014189781227412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae178dba403b2b%3A0xb13e12f773ce71fd!2sOGI%20TECHNOLOGIES!5e0!3m2!1sen!2sin!4v1732185390511!5m2!1sen!2sin"
                width="100px"
                height="380"
                style={{border:'0'}} 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
             </iframe>
            </div> 
      </section>
    </div>);
}, 'AppContact');

export default AppContact;