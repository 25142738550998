import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
  const content = props?.server?.props?.contentdata;
  console.log({ content });
  return (
    <section id="pricing">
      <div className="container">
        <h2 className="text-center pb-20 text-theme-color-2">
          Photo Editing Pricing
        </h2>
        <div className="section-content">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
              <div className="pricing-table maxwidth400">
                <div
                  className="font-18 pl-20 text-center text-white pr-20 p-10 text-uppercase"
                  style={{
                    backgroundColor: "rgb(102, 255, 255)",
                    fontWeight: 700,
                  }}
                >
                  Basic
                  <span className="font-15 pull-right mt-15 text-white"></span>
                </div>
                <img className='w-full' src={content.pricing.image1 + '&asimg=1'} style={{ width: '100%' }}></img>
                <div className="bg-white border-1px p-30 pt-20 pb-20">
                  <p
                    className="font-24 text-center"
                    style={{ color: "rgb(153, 153, 153)" }}
                  >
                    From<span className="text-theme-color-2"> 5.0$ </span>
                  </p>
                  <ul className="list check theme-colored pb-0">
                    <li>
                      <a href="/panorama-stitching-services">
                        360 Degree Photo Editing Services
                      </a>
                    </li>
                    {/* Add other list items */}
                  </ul>
                </div>{" "}
                <a
                  href="https://www.colourcubz.com/pricing"
                  className="btn btn-lg text-uppercase btn-block pt-20 pb-20 btn-flat"
                  style={{
                    backgroundColor: "rgb(179, 255, 255)",
                    color: "rgb(51, 51, 51)",
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Detail
                </a>
              </div>
            </div>
            {/* Repeat the above structure for other pricing tables */}
          </div>
        </div>
      </div>
    </section>
  );
}, "AppPricingDetails");
