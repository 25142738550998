import { useState } from "react";
import { constore } from "../../utils/mixins/mixin";



const fetchCartItems = (props, node) => {
  props.services.apiCurd("Frontend", {
    action: "getordereditems",
    with: ["service"],
    browser_fingerprint: props.services.getBrowserFingerprint().hash()
  }).then(({ data, lastOrder }) => {
    let payload = [...data.map(item => ({
      ...item,
      sum: item.qty * item.package.price
    }))];
    node.setCheckouts(payload);
  }).catch(err => {

  });
}

export default constore(function (props) {
  let [checkouts, setCheckouts] = useState([]);
  let fileurl = "/files/secure/image?fileId={fileId}&asimg=1";
  let subtotal = checkouts.reduce((c, item) => c + (item.sum), 0);
  let [discount, setDiscount] = useState(0);
  let [coupon, setCoupon] = useState();
  let [order, setOrder] = useState();
  let [sameAsBilling, setSameAsBilling] = useState(false);
  let [handling_charges, setHandlingChanges] = useState(0);
  let total = subtotal + handling_charges - discount;
  let node = {
    checkouts,
    total,
    discount,
    setCheckouts,
    setDiscount,
    setCoupon,
    setOrder,
    coupon,
    order
  };
  useState(() => {
    fetchCartItems(props, node);
  }, []);
  props.$setNode(node);
  return (
    <div>
      <section>
        <div className="container ">
          <div className="col-md-7">
            <div className="table-responsive">
              <table className="table table-striped table-bordered tbl-shopping-cart">
                <thead>
                  <tr>
                    <th></th>
                    <th>Order ID</th>
                    <th>Service Image</th>
                    <th>Service Name</th>
                    <th>Total</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (checkouts || []).map(item => (
                      <tr className="cart_item">
                        <td className="product-remove"><a title="Remove this item" onClick={() => removeItem(props, node, item)} className="remove" href="#">×</a></td>
                        <td className="product-price"><span className="order-id">{item.order_id}</span></td>
                        <td className="product-thumbnail"><a href="#"><img alt="member" src={fileurl.split("{fileId}").join(item.service.image)} /></a></td>
                        <td className="product-name"><a href="#"><strong>{item.service.name.toTitleCase()}</strong></a>
                          <ul className="variation">
                            <li className="variation-size"><strong>Services</strong>: <span className="" style={{ color: "#4CAF50;" }}><b>Included:</b></span></li>
                            <ul className="pt-5 pb-5">
                              {item.package.includes.split(",").map(
                                value => (
                                  <li><input type="checkbox" id="valid" style={{margin:"4px 5px 0"}} name="valid" value={value} checked />{value}</li>
                                )
                              )}

                            </ul>
                          </ul>
                        </td>
                        <td className="product-subtotal"><span className="amount">$ {item.sum}</span></td>
                        <td className="product-status"><span className="PaymentStatus" data-status={(item.order && item.order.payment_status||"")} > {(item.order && item.order.payment_status||"")}</span></td>
                        <td className="product-status"><span className="OrderStatus" data-status={(item.order && item.order.order_status || "")}>{(
                          item.order && item.order.order_status || (
                            item.order.payment_status=="success"?"progress":"")
                          )}</span></td>
                        {/* <td className="product-status"><span className="PaymentStatus" data-status={(item.payment && item.payment.status || "")} > {(item.payment && item.payment.status || "")}</span></td> */}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </section>

    </div>
  )
}, "AppCmOrders");