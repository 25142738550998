import React from "react";
import { styled } from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import AppInput from "./AppInput";
import { constore } from "../../utils/mixins/mixin";

const Overlay = styled.div`
    position: fixed;
	top: 0;
	left: 0;
	max-width: 100vw;
	max-height: 100vh;
	min-width: 100vw;
	min-height: 100vh;
	display: ${(props)=>props.display=='true'?'flex':'none'};
	justify-content: center;
	align-items: center;
    background-color: #0000007a;
    z-index: 1100;
	overflow: auto;
`;

const MessageCard = styled.form`
	display: ${p=>p.hide?'none':'flex'};
	flex-direction: column;
	justify-content: center;
	align-items: center;
    min-width: 500px;
    min-height: 150px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 1px 5px black;
	& .title {
		font-size: x-large;
		font-weight: 500;
		text-align: left;
		padding:10px;
	}
	&>.grow {
        display:flex;
		flex-grow:1;
		justify-content: center;
		align-items: center;
		padding: 10px;
		max-width: calc(100vw - 100px);
		max-height: calc(100vh - 100px);
		min-height: inherit;
		${p=>p.hasscroll?'overflow: auto;':''}
		width:100%;
		.loader {
			min-height: inherit;
			display:flex;
			justify-content: center;
			align-items: center;
			gap:15px;
		}
	}
	&>.footer {
		display: flex;
		gap: 10px;
		padding: 5px;
		&>button {
			padding: 5px 10px;
			background-color: var(--primary-color);
			border-radius: 5px;
			min-width: 100px;
			color: white;
		}
	}
`;

function AppLoader(props) {
	let [items,setItems] = useState([]);
	const defaultMessage = {
		content:"",
		title:"",
		hasFooter: false,
		hasscroll: false,
		btnOk: "OK",
		btnCancel: "CANCEL"
	};
	const message = items[items.length-1]||{};
    const active = !!message.content;
	const setMessage = (obj)=>{
		setItems((prev)=>{
			console.log("Creating messageId",{items:prev,messageId:obj.messageId,type:obj.type,obj});
			return ([
				...prev,
				{
					...defaultMessage,
					...obj
				}
			])
		});
	};
    const footerBtnClick = (action,option=({}))=>{
		let {data=null,event=null,messageId=message.messageId} = (option||{});
		console.trace("footerBtnClick",{messageId,option});
		setItems((prev)=>{
			let item = prev.find(o=>o.messageId==messageId);
			if(item) {
				if(action=="ok"&&item.resolve) {
					item.resolve(data);
				} else if(item.reject) {
					item.reject(event);
				}	
			} else {
				console.log("Promise Item Not Found");
			}
			console.log("Closing messageId",{messageId,items:prev});
			return [...prev.filter(o=>o.messageId!=messageId)]
		});
		
		// setMessage(defaultMessage);
	};
	useEffect(() => {
		globalThis.alert = (message="",config={hasFooter:true,btnCancel: "",type:"alert" })=>{
			const MessageComponent = typeof message == "function"?message:(()=>(<></>));
			let messageId = +Math.random().toString().slice(2);
			const promise = new Promise((res,rej) =>{
				let payload = {
					isstring: typeof message == "string",
					content:(typeof message == "function" )? ()=><MessageComponent
						close={(type='ok')=>footerBtnClick(type,{messageId})}
					/>: ()=>message,
					messageId,
					resolve: res,
					reject: rej,
					...config
				};
				setMessage({
					...defaultMessage,
					...payload
				});
			});
			
			return {
				promise,
				close: () => footerBtnClick('cancel',{messageId})
			};
		};
		globalThis.prompt = (message,fields=[],config={})=>{
			const PromptForm = ()=>{
				const [hasChange,setChange] = useState(false);
				const [inputs,setInputs] = useState(fields);
				const node = {
					fields,
					setChange:()=>setChange(!hasChange)
				};
				console.log("PromptForm",inputs);
				const onChange=(props,...args)=>{
					console.log("onChange",args);
					if(typeof props.onChange == "function") {
						props.onChange(...args,node);
					}
					if(typeof props.onFieldChange == "function") {
						props.onFieldChange(node,...args);
					}
				};
				return <div style={{width:'100%'}} className="px-4 py-2">
					{message && <h3 style={{textAlign:'center'}}>{message}</h3>}
					<div className="default-form flex flex-col" id="promptform" >
						{
							inputs.map((props,index) => <div className="mb-4" key={'field-'+index} >
								<AppInput type="text" {...props} onChange={(...args)=>onChange(props,...args)} ></AppInput>
							</div>)
						}
					</div>
				</div>
			};
			return globalThis.alert(<PromptForm></PromptForm>,{hasFooter:true,hasscroll:fields.length>4,type:"prompt",...config});
		};
		globalThis.confirm = (message,options={})=>{
			return globalThis.alert(message,{...options,hasFooter: true,type:"confirm"});
		};
		globalThis.loader = (message="",)=>{
			return globalThis.alert(<div className="loader">
				{<div>
					<div className="text-center" ><i class='fa fa-spinner fa-spin'></i></div>
					<div className="text-center"><span>{message||"Loading..."}</span></div>
				</div>}
			</div>,{hasFooter: false,type:"loader"});
		};
	}, []);

	useEffect(()=>{
		console.log("Updating messageId",{items,type:message?.type});
	},[items]);

	const handleSubmit = (event) =>{
		event.preventDefault();
		footerBtnClick('ok',{data:event.target.getData(),event});
	};
    props.$setNode({
        props,
		message,
		setMessage,
		items
    });
	return (
		<Overlay className="app-loader" display={(!!active).toString()}>
			{
				items.map((item)=>(
					<MessageCard hide={item.messageId != message.messageId} onSubmit={(...args) => handleSubmit(...args)} hasscroll={(!!item?.hasscroll)?1:0} >
						{item.title && (
							<div className="">
								<div className="col title">{item.title}</div>
							</div>
						)}
						<div className={Object.className({
							"grow": true,
							"flex": typeof item.isstring == "string"
						})} >
							{<item.content/>}
						</div>
						{item.hasFooter && (
							<div className="footer">
								{item.btnOk && <button type="submit" >{item.btnOk}</button>}
								{item.btnCancel && <button type="button" onClick={()=>footerBtnClick('cancel')} >{item.btnCancel}</button>}
							</div>
						)}
					</MessageCard>
				))
			}
		</Overlay>
	);
}

export default constore(AppLoader);
