import React, { useState } from "react";
import { constore } from "../utils/mixins/mixin";

function AppResetPassword(props) {
    let formAlert = props?.from == "alert";
    const handleSubmit = (e, props) => {
        e.preventDefault();
        let data = e.target.getData();
        props.services
            .apiCurd("Frontend", {
                action: "resetpassword",
                ...data,
            })
            .then((respose) => {
                if (props.close) {
                    props.close("ok");
                } else {
                    if (e?.target?.reset) {
                        e.target.reset();
                    }
                    location.href = props.buildpath("/login");
                }
            });
    };
    return (
        <>
            <div className="p-30">
                <div class="form-gap"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-md-offset-4">
                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <div class="text-center">
                                        <h3><i class="fa fa-lock fa-4x"></i></h3>
                                        <h3 className="h2 sunflower,sans-serif text-theme">
                                            Reset Password
                                        </h3>
                                        <p>You can reset your password here.</p>
                                        <div class="panel-body">
                                            <form
                                                id="login_form"
                                                name="login_form"
                                                method="post"
                                                action="javascript:void(0)"
                                                onSubmit={(e) => handleSubmit(e, props)}
                                            >
                                                <AppInput
                                                    label="Email address"
                                                    type="email"
                                                    id="username"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                />
                                                <div className="loginbtn">
                                                    <button
                                                        type={
                                                            formAlert
                                                                ? "button"
                                                                : "submit"
                                                        }
                                                        className="btn btn-theme btn-md mb-10"
                                                    >
                                                        Send Reset Link
                                                    </button>
                                                    <div>
                                                            <h5 style={{  display: 'block', textAlign: 'center', fontFamily: 'Geneva, Verdana, sans-serif' }}>or </h5>
                                                    </div>

                                                    <div>
                                                        <a href={props.buildpath("/login")} style={{ color: 'blue', display: 'block', textAlign: 'center', textDecoration: 'underline', fontFamily: 'Geneva, Verdana, sans-serif' }}>
                                                            Click here for login 
                                                        </a>
                                                    </div>

                                                    <br />
                                                </div>
                                                <br />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default constore(AppResetPassword);
