const files = import.meta.glob('./**/*.jsx', { eager: true });
const file_keys = Object.keys(files)
.filter(key=>{
    let name = key.filename();
    let indexFile = `${name}/index.jsx`;
    let nameFile = `${name}/${name}.jsx`;
    return (key.endsWith(indexFile)||key.endsWith(nameFile));
});
const pages = {
    ...file_keys.map(key=>{
        let name = key.filename();
        let index = key.lastIndexOf(name);
        let startpath = key.substring(1,index-1);
        return ({
            path:startpath,
            Element:files[key].default,
            startpath
        })
    })
}
export default pages;
