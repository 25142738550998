import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {

  const getService = (name) => props.getProps('data.service.' + name);
  const packcolor = {
    "basic": "red",
    "pro": "green",
    "premium": "red",
  };
  return (
    <>
      <section id="home">
        <div className="maximage-slider responsive-banner">
          <div id="maximage" className="mc-cycle" style={{ height: '919px', width: '1920px' }}>
            <div className="mc-image" title="" style={{
              backgroundImage: 'url("/images/slider/b7.jpg")',
              width: '100%',
              objectFit: 'contain'
            }} data-href=""></div>
          </div>
        </div>
      </section>
      <div className="container pt-50">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12">
              <div className="meet-doctors">
                <h1 className="mt-0 font-29 text-center pb-20">
                  <span className="text-theme-color-2">{getService('title')}</span>
                </h1>
                <div style={{ textAlign: 'justify' }}>
                  {getService('description').split("\n").map((data, i) => <p key={i} >{data}</p>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container">
        <div className="row">
          <div className="col-md-12 blog-pull-right mob-hide">
            <div className="row">
              <h2 className="mt-0 line-height-1 font-28 text-center pb-50">
                <span className="text-theme-color-2"> Our {getService('title')}</span>
              </h2>
              {getService('feature_list').map(feature => <div className="col-md-4 pb-20">
                <div className="service-block bg-white">
                  <div className="col-md-12">
                    <div className="col-md-5">
                      <div className="thumb">
                        <img alt="featured project" src="/files/secure/colorcubz.jpg?fileId=9&rd=41&asimg=1"
                          style={{
                            width: "100%",
                            objectFit: 'contain'
                          }} />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div>
                        <p className="font-14 text-uppercase">
                          <strong>{feature.title}</strong>
                        </p>
                        <p className="font-11">{feature.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </section>
      <section class="bg-lighter">
        <h3 className="text-center city-head-font pb-30 pt-20 text-theme-colored"> Our Pricing Packages for Product {getService('title')} </h3>
        <div className="section-content">
          <div className="container">
            <div className="row">
              {getService('service_packages').map((pack) => <div className="col-xs-12 col-sm-6 col-md-4 hvr-float-shadow mb-sm-30">
                <div className="pricing-table maxwidth400">
                  <div className="font-16 pl-20 text-center pr-20 p-10" style={{ backgroundColor: packcolor[pack.name] }}>
                    <strong className="text-white font-20 text-center">{pack.title}</strong></div>
                  <img src={"/files/secure/colorcubz.jpg?fileId="+pack.image+"&rd=41&asimg=1"}
                    style={{
                      width: "100%",
                      objectFit: 'contain'
                    }} alt="" />
                  <div className=" bg-white border-1px p-30 pt-20 pb-30">
                    <p className="font-28 m-0 text-center text-black">
                      <span className="text-theme-color-2">$ </span>{pack.price || 1}</p>
                    <p className="text-center font-18 ">Per Image</p>
                    <ul className="list check theme-colored pb-0" style={{ height: '460px' }}>
                      {pack.includes.split(',').map(value => <li>{value}</li>)}
                    </ul>
                  </div>
                  <a href="https://www.colourcubz.com/order-summary" className="btn btn-lg btn-theme-colored  btn-block pt-15 pb-15 btn-flat">
                    <span className="text-uppercase text-white">Buy Now</span>
                  </a>
                </div>
              </div>)}

            </div>
          </div>
        </div>
      </section>
    </>

  )
}, "App360DegreeEditingServices");
