import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppGallery from "../../components/AppGallery";
export default constore(function () {
    return (
        <>
            <AppGallery/>
            <AppFooter/>
        </>
    )
}, "gallery");