import React, { useEffect, useState } from "react";
import { constore } from "../utils/mixins/mixin";

function AppServicesMegamenu(props) {
    const [services, setServices] = useState(props.$node?.server?.props?.data?.services || []);
    console.log("props?.server?.props?.contentdata", props?.server?.props?.contentdata, props);
    const getService = (name) => props.getcontent('home.services' + (name ? `.${name}` : ''));
    return (
        <li className="service-megamenu" style={{ marginRight: '0px', }}> 
            <a className="btn bg-white font-14 bs-modal-ajax-load mt-0 ml-0 pr-15 pl-15" href={props.buildpath("/services")} style={{ color: '#042a61', fontWeight: 'bold', borderRadius: '15px'}}            >
                <i className="fa fa-list "></i>
                <span>Services</span>
                <span className="label label-info">
                </span>
            </a>
            <div className="megamenu" style={{ right: '0px', display: 'none' }}>
                <div className="megamenu-row mob-big-box">
                    {
                        services.map(service =>
                            <div className="page-container mt-4"> 
                            
                            <div className="col-md-3 pb-sm-2">
                              <div className="image-box-thum mb-3" >
                                <a href={props.buildpath("/"+service.name)} >
                                  <img
                                    className="img-fullwidth" 
                                    alt={service.title}
                                    src={`/files/secure/image?fileId=${service.image || 1}&asimg=1` }
                                    style={{ height: '200px', width: '100%', objectFit: 'cover', objectPosition: 'center' }}
                                  />
                                 
                                </a>
                              </div>
                              <div className="service-bg">
                              <div className="border-1px p-2 bg-theme-colored mob-box">
                                <a href={props.buildpath("/"+service.name)} >
                                  <h5 className="mt-0 pt-5 text-center" style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' ,fontFamily: 'Geneva, Verdana, sans-serif'}}>
                                    {service.title}
                                  </h5>
                                </a>
                              </div>
                              </div>
                            </div>
                           
                          </div>
                                      
                        )
                    }
                </div>
            </div>
        </li>
    );
}; export default constore(AppServicesMegamenu, "AppServicesMegamenu");

