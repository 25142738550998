import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppCheckout from "./AppCheckout";
// import AppCartPopUp from "./AppCartPopUp";

export default constore(function () {
    return (
        <>  
            {/* <AppCartPopUp/> */}
            <AppCheckout/>
            <AppFooter/>
        </>
    )
}, "AppCart");