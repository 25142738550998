import React from 'react'

const AppForgotPassword = () => {
  return (
    <div>

<div class="form-gap"></div>
<div class="container">
	<div class="row">
		<div class="col-md-4 col-md-offset-4">
            <div class="panel panel-default">
              <div class="panel-body">
                <div class="text-center">
                  <h3><i class="fa fa-lock fa-4x"></i></h3>
                  <h2 class="text-center">Forgot Password?</h2>
                  <p>You can reset your password here.</p>
                  <div class="panel-body">
    
                    <form id="register-form" role="form" autocomplete="off" class="form" method="post">
    
                      <div class="form-group">
                      <div className="input-group">
                  <span className="input-group-addon"><i className="glyphicon glyphicon-envelope"></i></span>
                  <AppInput type="email" id="email" placeholder="Enter email" name="email" required />
                </div>
                      </div>
                      <div class="form-group">
                      <button type="submit" className="btn btn-theme-colored btn-block">Reset Password</button>
                        
                      </div>
                      
 
                    </form>
    
                  </div>
                </div>
              </div>
            </div>
          </div>
	</div>
</div>
       

 

    </div>
  )
}

export default AppForgotPassword