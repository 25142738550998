import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppServiceList from "../../components/AppServiceList";
import AppServicesTitleHeadline from "../../components/AppServicesTitleHeadline";
import AppWhyYouChoose from "../home/AppWhyYouChoose";
import AppGetStarted from "../home/AppGetStarted";
import AppKeyFeature from "../home/AppKeyFeature";
import AppRegistrationForm from "../../components/AppRegistrationForm";

import AppUploadPhotosTitleLabel from "../uploadphotos/AppUploadPhotosTitleLabel";

export default constore(function () {
    return (
        <>
            <AppServicesTitleHeadline />
            <AppServiceList />
            <AppUploadPhotosTitleLabel/>
            <AppWhyYouChoose/>
            <AppKeyFeature />
            <AppUploadPhotosTitleLabel/>
            <AppGetStarted/>
            <AppRegistrationForm payload={{"enquiry_type":"others"}} />
            <AppFooter />
        </>
    )
}, "services");