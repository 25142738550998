import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppGuestUser from "../../components/AppGuestUser";


export default constore(function () {
    return (
        <>
            <AppGuestUser/>
            <AppFooter/>
        </>
    )
}, "guestuser");