import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";

import AppUploadPhotosTitleLabelDetails from "./AppUploadPhotosTitleLabelDetails";
import AppUploadPhotosTitleLabel from "./AppUploadPhotosTitleLabel";


export default constore(function () {
    return (
        <>
{/*  
            <AppUploadPhotosTitleLabel/>   */}
            <AppUploadPhotosTitleLabelDetails/>
            <AppFooter/>
        </>
    )
}, "uploadphotos");