import React from 'react';

const AppPhotoRetouchDescription = () => {
  return (
    <div>
      <section>
        <div>
      <div class="h-bg pt-10">
        <h2 class="text-center text-theme-colored line-height-15 ">Photo Retouching Services | Professional Photo Editing</h2></div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12">
                <div className="meet-doctors">
                  <p>
                    Professional photo retouching services provided by FixThePhoto perfectly meet requirements of a professional photographer who
                    wants to save time or a beginner who wants images to be professionally edited. FixThePhoto retouching services are professional,
                    affordable and secure. Enjoy amazing results provided by our qualified photo retouchers. Do you need to get photos edited?
                    Contact us; we are ready to start working.
                  </p>
                  <p>
                    FixThePhoto retouching services are professional, affordable and secure. Enjoy amazing results provided by our qualified
                    photo retouchers. Do you need to get photos edited? Contact us; we are ready to start working. FixThePhoto retouching
                    services are professional, affordable and secure. Enjoy amazing results provided by our qualified photo retouchers.
                    Do you need to get photos edited? Contact us; we are ready to start working.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-10 mob-hide">
        <div className="container pt-0 pb-0">
          <div className="section-content">
            <h3 style={{ display: 'none' }}>Colourcubz Professional Photo Editing</h3>
            <div data-margin-top="-10px" className="row pb-10" style={{ marginTop: '-10px' }}>
              {/* Card 1 */}
              <div className="col-md-3">
                <div className="card effect__hover">
                  <div>
                  <div className="card__text border-3px card__front" style={{ backgroundColor: 'rgb(168, 234, 255)' , 'background-Size':  '100%' , 'height': '75%','width': '100%'}}>
                    <img alt="" src="images/teacher2.jpg" className="img-fullwidth" />
                    <div className="icon-box mb-0">
                      <a href="#" className="mb-0">
                        <i className="icon-style pe-7s-upload"></i>
                      </a>
                    </div>
                  </div>
                  <div data-bg-color="#102c45" className="card__text card__back" style={{ background: 'rgb(16, 44, 69) !important',  }}>
                    <h3 className="text-theme-colored pt-5">Quick Upload</h3>
                    <p className="text-gray-lightgray" style={{ marginLeft: '5px', marginRight: '5px' }}>
                    Submit your images along with your requirements to get delivered within the 
                          fastest turnaround time (less than 24 hours).
                      </p>
                  </div>
                </div>
                </div>
                <div className="text-center mt-50 mb-20 font-17">
                  <p>
                    <a href="#" className="normal text-center">
                      QUICK UPLOAD
                    </a>
                  </p>
                </div>
              </div>

              {/* Card 2 */}

              <div className="col-md-3">
                <div className="card effect__hover">
                  <div>
                  <div className="card__text border-3px card__front" style={{ backgroundColor: 'rgb(168, 234, 255)' ,'background-Size':  '100%' , 'height': '75%','width': '100%'}}>
                    <img alt="" src="images/teacher2.jpg" className="img-fullwidth" />
                    <div className="icon-box mb-0">
                      <a href="#" className="mb-0">
                        <i className="icon-style pe-7s-users"></i>
                      </a>
                    </div>
                  </div>
                  <div data-bg-color="#102c45" className="card__text card__back" style={{ background: 'rgb(16, 44, 69) !important' }}>
                    <h3 className="text-theme-colored pt-5">Customer Support</h3>
                    <p className="text-gray-lightgray" style={{ marginLeft: '5px', marginRight: '5px' }}>
                      24/7 customer support to all client inquiries
                      </p>
                  </div>
                </div>
                </div>
                <div className="text-center mt-50 mb-20 font-17">
                  <p>
                    <a href="#" className="normal text-center">
                      CUSTOMER SUPPORT
                    </a>
                  </p>
                </div>
              </div>

              {/* Card 3 */}
              <div className="col-md-3">
                <div className="card effect__hover">
                  <div>
                    <div className="card__text border-3px card__front" style={{ backgroundColor: 'rgb(168, 234, 255)','background-Size':  '100%' , 'height': '75%','width': '100%' }}>
                      <img alt="" src="images/teacher2.jpg" className="img-fullwidth" />
                      <div className="icon-box mb-0">
                        <a href="#" className="mb-0">
                          <i className="icon-style pe-7s-phone"></i>
                        </a>
                      </div>
                    </div>
                    <div data-bg-color="#102c45" className="card__text card__back" style={{ background: 'rgb(16, 44, 69)', '!important': true }}>
                      <h3 className="text-theme-colored pt-5">Raw Supported</h3>
                      <p className="text-gray-lightgray" style={{ marginLeft: '5px', marginRight: '5px' }}>
                        We are flexible in handling image formats such as JPEG, TIFF, BMP, PDF, TGA, GIF, JBIG, X PIXMAP, PSD, PNG and more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-50 mb-20 font-17">
                  <p>
                    <a href="#" className="normal text-center">
                      RAW SUPPORTED
                    </a>
                  </p>
                </div>
              </div>

              {/* Card 4 */}
              <div className="col-md-3">
                <div className="card effect__hover">
                  <div>
                    <div className="card__text border-3px card__front" style={{ backgroundColor: 'rgb(168, 234, 255)','background-Size':  '100%' , 'height': '75%','width': '100%' }}>
                      <img alt="" src="images/teacher2.jpg" className="img-fullwidth" />
                      <div className="icon-box mb-0">
                        <a href="#" className="mb-0">
                          <i className="icon-style pe-7s-lock"></i>
                        </a>
                      </div>
                    </div>
                    <div data-bg-color="#102c45" className="card__text card__back" style={{ background: 'rgb(16, 44, 69)', '!important': true }}>
                      <h3 className="text-theme-colored pt-5">Security</h3>
                      <p className="text-gray-lightgray" style={{ marginLeft: '5px', marginRight: '5px' }}>
                        Uncompromised security over your business data from the collection and delivery of your resultant images
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-10 mb-20 font-17">
                  <p>
                    <a href="#" className="normal text-center">
                      SECURITY
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppPhotoRetouchDescription;
