import { constore } from "../utils/mixins/mixin";
export default constore(function () {
    return ( <section id="event" className="">
      <div className="container pb-50">
        <div className="section-content">
          <div className="row">
            <div className="col-md-6">
              <h3 className="text-uppercase line-bottom mt-0 line-height-1"><i className="fa fa-calendar mr-10"></i>Upcoming <span className="text-theme-color-2">Events</span></h3>
              <article className="post media-post clearfix pb-0 mb-10">
                <a href="#" className="post-thumb mr-20"><img alt="" src="http://placehold.it/120x120"/></a>
                <div className="post-right">
                  <h4 className="mt-0 mb-5"><a href="#">Upcoming Event Title</a></h4>
                  <ul className="list-inline font-12 mb-5">
                    <li className="pr-0"><i className="fa fa-calendar mr-5"></i> June 26, 2016 |</li>
                    <li className="pl-5"><i className="fa fa-map-marker mr-5"></i>New York</li>
                  </ul>
                  <p className="mb-0 font-13">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas eveniet, nemo dicta. Ullam nam.</p>
                  <a className="text-theme-colored font-13" href="#">Read More →</a>
                </div>
              </article>
              <article className="post media-post clearfix pb-0 mb-10">
                <a href="#" className="post-thumb mr-20"><img alt="" src="http://placehold.it/120x120"/></a>
                <div className="post-right">
                  <h4 className="mt-0 mb-5"><a href="#">Upcoming Event Title</a></h4>
                  <ul className="list-inline font-12 mb-5">
                    <li className="pr-0"><i className="fa fa-calendar mr-5"></i> June 26, 2016 |</li>
                    <li className="pl-5"><i className="fa fa-map-marker mr-5"></i>New York</li>
                  </ul>
                  <p className="mb-0 font-13">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas eveniet, nemo dicta. Ullam nam.</p>
                  <a className="text-theme-colored font-13" href="#">Read More →</a>
                </div>
              </article>
              <article className="post media-post clearfix pb-0 mb-10">
                <a href="#" className="post-thumb mr-20"><img alt="" src="http://placehold.it/120x120"/></a>
                <div className="post-right">
                  <h4 className="mt-0 mb-5"><a href="#">Upcoming Event Title</a></h4>
                  <ul className="list-inline font-12 mb-5">
                    <li className="pr-0"><i className="fa fa-calendar mr-5"></i> June 26, 2016 |</li>
                    <li className="pl-5"><i className="fa fa-map-marker mr-5"></i>New York</li>
                  </ul>
                  <p className="mb-0 font-13">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas eveniet, nemo dicta. Ullam nam.</p>
                  <a className="text-theme-colored font-13" href="#">Read More →</a>
                </div>
              </article>
            </div>
            <div className="col-md-6">
              <h3 className="line-bottom mt-0 line-height-1">Why <span className="text-theme-color-2">Choose Us?</span></h3>
              <p className="mb-10">The Cweren Law Firm is a recognized leader in landlord tenant representation throughout Texas.The largest professional property.</p>
              <div id="accordion1" className="panel-group accordion">
                <div className="panel">
                  <div className="panel-title"> <a className="active" data-parent="#accordion1" data-toggle="collapse" href="#accordion11" aria-expanded="true"> <span className="open-sub"></span> Why this Company is Best?</a> </div>
                  <div id="accordion11" className="panel-collapse collapse in" role="tablist" aria-expanded="true" style="">
                    <div className="panel-content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore impedit quae repellendus provident dolor iure poss imusven am aliquam. Officiis totam ea laborum deser unt vonsess.  iure poss imusven am aliquam</p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title"> <a data-parent="#accordion1" data-toggle="collapse" href="#accordion12" className="collapsed" aria-expanded="false"> <span className="open-sub"></span> Why this Company is Best?</a> </div>
                  <div id="accordion12" className="panel-collapse collapse" role="tablist" aria-expanded="false" style="height: 0px;">
                    <div className="panel-content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore impedit quae repellendus provident dolor iure poss imusven am aliquam. Officiis totam ea laborum deser unt vonsess.  iure poss imusven am aliquam</p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title"> <a data-parent="#accordion1" data-toggle="collapse" href="#accordion13" className="collapsed" aria-expanded="false"> <span className="open-sub"></span> Why this Company is Best?</a> </div>
                  <div id="accordion13" className="panel-collapse collapse" role="tablist" aria-expanded="false" style="height: 0px;">
                    <div className="panel-content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore impedit quae repellendus provident dolor iure poss imusven am aliquam. Officiis totam ea laborum deser unt vonsess.  iure poss imusven am aliquam</p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title"> <a data-parent="#accordion1" data-toggle="collapse" href="#accordion14" className="collapsed" aria-expanded="false"> <span className="open-sub"></span> Why this Company is Best?</a> </div>
                  <div id="accordion14" className="panel-collapse collapse" role="tablist" aria-expanded="false" style="height: 0px;">
                    <div className="panel-content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore impedit quae repellendus provident dolor iure poss imusven am aliquam. Officiis totam ea laborum deser unt vonsess.  iure poss imusven am aliquam</p>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div className="panel-title"> <a data-parent="#accordion1" data-toggle="collapse" href="#accordion15" className="collapsed" aria-expanded="false"> <span className="open-sub"></span> Why this Company is Best?</a> </div>
                  <div id="accordion15" className="panel-collapse collapse" role="tablist" aria-expanded="false" style="height: 0px;">
                    <div className="panel-content">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore impedit quae repellendus provident dolor iure poss imusven am aliquam. Officiis totam ea laborum deser unt vonsess.  iure poss imusven am aliquam</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>)
},"AppUpcomingEvents");

