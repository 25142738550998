import { constore } from "../utils/mixins/mixin";

export default constore(function (props) {
    return (<div>
<section>
      <div className="container h-bg pt-10">
      <div className="section-content">
        <h2 className="text-center text-uppercase mt-0 line-height-1 pb-50 ">
          <span className="text-theme-color-2 ">SERVICES | THIS IS WHAT WE DO</span>
        </h2>
        <div className="row">
          <div className="col-md-6">
            <div className="widget">
              <div className="latest-posts">
                <article className="post media-post clearfix pb-0 mb-20">
                  <a href="/panorama-stitching-services" className="post-thumb">
                    <img alt="360 Degree Photo Editing Services" src="/images/services/service1_300x220.jpg" width="293" height="212" /></a>
                  <div className="post-right">
                    <p className="mt-0 pt-40 font-18">
                      <a href="/panorama-stitching-services" className="text-theme-color-2">360 Degree Photo Editing Services</a>
                    </p>
                    <p className="font-10">The portrayal of images by skillful stitching with round, straight-lined, and full-frame stitching as per panorama requirements. </p>
                    <p><strong>USD $ 1.60</strong>
                    </p>
                  </div>
                </article>
                <article className="post media-post clearfix pb-0 mb-20">
                  <a href="/product-photo-editing-services" className="post-thumb">
                    <img alt="Product Photo Editing Services" src="/images/services/service3_300x220.jpg" width="293" height="212" /></a>
                  <div className="post-right">
                    <p className="mt-0 pt-40 font-18"><a href="/product-photo-editing-services" className="text-theme-color-2">Product Photo Editing Services</a></p>
                    <p className="font-10">Enhancement of automobile, jewelry, apparel &amp; furniture images by eliminating/adjusting unwanted glares, noises, backgrounds, &amp; watermarks, etc</p>
                    <p><strong>USD $ 1.60</strong>
                    </p>
                  </div>
                </article>
                <article className="post media-post clearfix pb-0 mb-20"><a href="/portrait-retouching-services" className="post-thumb">
                  <img alt="Portrait Retouching Services" src="/images/services/service5_300x220.jpg" width="293" height="212" /></a>
                  <div className="post-right"><p className="mt-0 pt-40 font-18"><a href="/portrait-retouching-services" className="text-theme-color-2">Portrait Retouching Services</a></p>
                    <p className="font-10">Transforming your portraits by retouching removal/adjusting skin imperfections and other spots, making it smooth and flawless.</p>
                    <p><strong>USD $ 1.60</strong>
                    </p>
                  </div>
                </article>
                <article className="post media-post clearfix pb-0 mb-20">
                  <a href="/real-estate-floor-plan-creation-services" className="post-thumb">
                    <img alt="Real Estate Floor Plan  Services" src="/images/services/service7_300x220.jpg" width="293" height="212" /></a>
                  <div className="post-right"><p className="mt-0 pt-40 font-18">
                    <a href="/real-estate-floor-plan-creation-services" className="text-theme-color-2">Real Estate Floor Plan  Services</a></p>
                    <p className="font-10">Conversion of basic one-dimensional floor plans to 360 or 180 with drawings, sketches, CAD files, 2D/3D floor plan development, etc.</p>
                    <p><strong>USD $ 1.60</strong></p>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="widget">
              <div className="latest-posts"><article className="post media-post clearfix pb-0 mb-20"><a href="/image-manipulation-services" className="post-thumb">
                <img alt="Image Manipulation Services" src="/images/services/service2_300x220.jpg" width="293" height="212" /></a>
                <div className="post-right">
                  <p className="mt-0 pt-40 font-18">
                    <a href="/image-manipulation-services" className="text-theme-color-2">Image Manipulation Services</a></p>
                  <p className="font-10">Alteration/modification of images by techniques by cinemagraph creation, vector graphics editing, correcting color &amp; density</p>
                  <p><strong>USD $ 1.60</strong>
                  </p>
                </div>
              </article>
                <article className="post media-post clearfix pb-0 mb-20">
                  <a href="/real-estate-photo-editing-services" className="post-thumb">
                    <img alt="Real Estate Editing Photo Services" src="/images/services/service4_300x220.jpg" width="293" height="212" /></a>
                  <div className="post-right"><p className="mt-0 pt-40 font-18">
                    <a href="/real-estate-photo-editing-services" className="text-theme-color-2">Real Estate Editing Photo Services</a></p>
                    <p className="font-10">Driving engagement towards real estate listings by elimination/adjustment of elements, sky replacements, development of 3D tours.</p>
                    <p>
                      <strong>USD $ 1.60</strong>
                    </p>
                  </div>
                </article>
                <article className="post media-post clearfix pb-0 mb-20">
                  <a href="/image-clipping-services" className="post-thumb">
                    <img alt="Image Clipping Services" src="/images/services/service6_300x220.jpg" width="293" height="212" /></a>
                  <div className="post-right">
                    <p className="mt-0 pt-40 font-18">
                      <a href="/image-clipping-services" className="text-theme-color-2">Image Clipping Services</a></p>
                    <p className="font-10">Clipping real estate, furniture, food, jewelry, automobile, and photo images altering background, enhancing its look &amp; feel.</p>
                    <p><strong>USD $ 1.60</strong>
                    </p>
                  </div>
                </article>
                <article className="post media-post clearfix pb-0 mb-20">
                  <a href="/photo-restoration-services" className="post-thumb">
                    <img alt="Photo Restoration Services" src="/images/services/service8_300x220.jpg" width="293" height="212" /></a>
                  <div className="post-right"><p className="mt-0 pt-40 font-18"><a href="/photo-restoration-services" className="text-theme-color-2">Photo Restoration Services</a></p>
                    <p className="font-10">Restoration of old images to required formats with skillful elimination of blotches, dirt or any damages caused by aging.</p>
                    <p><strong>USD $ 1.60</strong></p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section> 

    </div>);
}, "AppServicesWhatWeDo");