import { constore } from "../../utils/mixins/mixin";
import AppResetPassword from "../../components/AppResetPassword";
import "./forgotpassword.css";
import AppFooter from "../../components/AppFooter";

export default constore(function () {
    return (
        <>
            {/* <AppLoginPopup/>  */}
            <AppResetPassword />
            <AppFooter/>
            
            
        </>
    );
}, "AppForgetPassword");
