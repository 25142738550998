import React from 'react';
import { useState } from "react";
import { constore } from "../../utils/mixins/mixin";
import AppCmOrders from './AppCmOrders';


const formatDate = (dateString) => {
    const options = {month: 'numeric',day: 'numeric',    year: 'numeric', };
    return new Date(dateString).toLocaleDateString(undefined, options);
};


const fetchOrderItems = (props, node) => {
    props.services.apiCurd("Frontend", {
      tablename:"orders",
      action: "list",
      user_id: props.server.user.id
    }).then(({ data }) => {
      node.setOrders(data);
    }).catch(err => {
  
    });
  }

const AppCmProcess = constore((props) => {
    let [orders, setOrders] = useState([]);
    let node = {
        props,
        orders,
        setOrders
    };
    props.$setNode(node);
    useState(() => {
        fetchOrderItems(props, node);
      }, []);
      props.$setNode(node);
    return (
        <div>
            <section>
                <div className="container ">

                    <div id="accordion1" className="panel-group accordion ">
                        <div className="panel ">

                            <div className="row col-md-12">
                                <div className="col-md-7">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered tbl-shopping-cart">

                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Date</th>
                                                    <th>Order ID</th>
                                                    <th>Uploaded File</th>
                                                    <th>Download Processed File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (orders || []).map(item =>(
                                                <tr className="cart_item">
                                                    <td className="product-remove"><a title="Remove this item" onClick={() => removeItem(props, node, item)} className="remove" href="#">×</a></td>
                                                    
                                                    <td className="product-name"><a href="#">{formatDate(item.created_at)}</a> </td>
                                                    <td className="product-id"><a href="#">{item.id}</a> </td>
                                                    <td>
                                                        {
                                                            item.service_file && <a href={'/files/secure/Logo.jpg?fileId='+item.service_file+'&rd=1&asimg=1'} title='Uploaded File Link ' target='__blank' style={{color:'blue'}}>
                                                                Uploaded Files 
                                                            </a>
                                                        }
                                                        {
                                                            !item.service_file && <span>File in not uploaded</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            item.serviced_file && <a href={'/files/secure/Logo.jpg?fileId='+item.serviced_file+'&rd=1&download=1'} title='Processed File Link ' target='__blank' style={{color:'blue'}} >
                                                                Processed Files
                                                            </a>
                                                        }
                                                        {
                                                            !item.serviced_file && <span>File in not uploaded</span>
                                                        }
                                                    </td>
                                                </tr>

                                                    )) 
                                                    

                                                }
                                                
                                            </tbody>
                                        </table>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
},'AppCmProcess');

export default AppCmProcess