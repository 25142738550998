import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
  const viewData = props.data;
  const getContent = (name) => {
    if(viewData) {
      return Object.getNested(viewData.parse(),"whychooseus"+(name ? `.${name}` : ''));
    } else {
      return props.getcontent(`home.whychooseus` + (name ? `.${name}` : ''))
    }
  };
  const sliders = Object.values(getContent('slider') || []);
  props.$setNode({
    sliders
  });

  console.log("whychooseus",getContent(""));
  return (
    <section className="pt-30 pb-50 bg-white">
      <div className="container pt-0 pb-0">
        <div className="section-content">
          <div className="row pt-10">
            <div className="col-md-12">
              <div className="meet-doctors">
                <h2 className="mb-2 line-height-1 text-center">
                  <span className="text-uppercase text-bold text-theme-colored" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>Why Choose Us?</span>
                </h2>
                <div className="col-md-6">
                  <ul style={{ listStyle: 'disc', textAlign: 'justify', fontSize: '15px' }}>
                    {Array.from({ length: 11 }).map((_, index) => (
                      <li style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} key={index}>{getContent(`points.${index}.value`)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-md-6 ml-0">
                <div className="why-choose owl-carousel owl-theme owl-loaded">
                  {sliders.map((slider, index) => (
                    <div key={index} className="owl-item" style={{ width: '100%', marginRight: '0px', marginTop:'20px' }}>
                      <div className="item">
                        <article className="post clearfix maxwidth600 mb-sm-30">
                          <div className="entry-header">
                            <div className="post-thumb thumb pt-20">
                              <img className="w-full" src={slider.img} alt={slider.title} style={{
                                backgroundPosition: 'center',
                                marginTop:'10px',
                                height: '300px',
                                objectFit: 'cover',
                                width: '100%'
                              }} />
                            </div>
                          </div>
                          <div className="border-1px p-15" style={{backgroundColor:'#162378'}}>
                            <p className="mt-0 pt-0 text-white text-center font-18">
                              {slider.title}
                            </p>
                            <div className="clearfix"></div>
                          </div>
                        </article>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}, "AppWhyYouChoose");
