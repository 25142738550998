import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppPrivacyAndPolicy1 from "./AppPrivacyAndPolicy1";

export default constore(function () {
    return (
        <>
            <AppPrivacyAndPolicy1/>
            <AppFooter/>
        </>
    )
}, "privacy-policy");
