import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppRegistrationForm from "../../components/AppRegistrationForm";

export default constore(function () {
    return (
        <>
            <AppRegistrationForm page={"registrationform"} />
            <AppFooter/>
        </>
    )
}, "registrationform");


