import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppUpcomingEvents from "../../components/AppUpcomingEvents";

export default constore(function () {
    return (
        <>
            <AppUpcomingEvents/>
            <AppFooter/>
        </>
    )
}, "upcomingevents");