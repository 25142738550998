import { constore } from "../../utils/mixins/mixin";
import AppFooter from "../../components/AppFooter";
import AppSubscribe from "../../components/AppSubscribe";

export default constore(function () {
    return (
        <>
            <AppSubscribe/>
            <AppFooter/>
        </>
    )
}, "subscribe");