import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import React from 'react'
import PaymentForm from "./PaymentForm"


const PUBLIC_KEY ="pk_test_51OSZFMSAPH24XbK8dKXndXdF7c9rXN7p4S1C672wxCKWe0urmMXXo8vRl5Mh62Ym1PtL5L315BTKlsCu8yFiP4al00R8dwFgRP"

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer() {
  return (
    <Elements stripe={stripeTestPromise}>

            <PaymentForm/>

    </Elements>
  )
}
