import { useEffect } from "react";

import RequestInfo from "./AppHomeRequest/RequestInfo";

const setupAbout = () => {
    $("#reservation_form").validate({
        submitHandler: function (form) {
            var form_btn = $(form).find('button[type="submit"]');
            var form_result_div = '#form-result';
            $(form_result_div).remove();
            form_btn.before('<div id="form-result" class="alert alert-success" role="alert" style="display: none;"></div>');
            var form_btn_old_msg = form_btn.html();
            form_btn.html(form_btn.prop('disabled', true).data("loading-text"));
            $(form).ajaxSubmit({
                dataType: 'json',
                success: function (data) {
                    if (data.status == 'true') {
                        $(form).find('.form-control').val('');
                    }
                    form_btn.prop('disabled', false).html(form_btn_old_msg);
                    $(form_result_div).html(data.message).fadeIn('slow');
                    setTimeout(function () { $(form_result_div).fadeOut('slow') }, 6000);
                }
            });
        }
    });
}

export default constore((props) => {
    const content = props.getcontent('home.about');
    const points = props.getcontent('home.about.points') || [];
    useEffect(() => { })
    return (
        <section>
            <div class="container pb-30">
                <div class="section-content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="meet-doctors">
                                <h1 class="text-uppercase mt-0 line-height-1 responsive-title" style={{ fontSize: '28px', textAlign:'center' }}>
                                    {content?.title}
                                </h1>
                                <h6 class="responsive-tagline text-uppercase letter-space-5 line-bottom title font-playfair text-uppercase" style={{ textAlign:'center' }}>
                                    {content?.tagline}
                                </h6>
                                <p style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px', }}>
                                    {content?.description}
                                </p>
                            </div>
                            <div class="row mb-sm-30">
                                {
                                    points.map((point, index) => (
                                        <div key={index} className="col-sm-6 col-md-6">
                                            <div className="icon-box p-0 mb-20">
                                                <a className="icon icon-circled icon-border-effect effect-circle icon-sm pull-left sm-pull-none flip" href="">
                                                        <img key={index} src={`/icon/image_${index + 1}.jpg`} className="icon-img" alt={`Image ${index + 1}`} />
                                                </a>
                                                <div className="ml-70 ml-sm-0">
                                                    <h5 className="icon-box-title mt-5 text-uppercase text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', fontSize: '16px' }}>{point.title}</h5>
                                                    <p className="text-gray" style={{ textAlign: 'justify', lineHeight: '1.5', fontSize: '16px' }}>{point.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {/* <div class="col-md-4">
                            <RequestInfo/>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}, "AppHomeRequest");
