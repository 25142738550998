import { useEffect, useState } from "react";
import { constore } from "../utils/mixins/mixin";

// import { useEffect } from "react";
const setupCarosel = ($node) => {
    var revapi = $(document).ready(function (e) {
        $(".rev_slider").revolution({
            sliderType: "standard",
            sliderLayout: "auto",
            dottedOverlay: "none",
            delay: 5000,
            navigation: {
                keyboardNavigation: "off",
                keyboard_direction: "horizontal",
                mouseScrollNavigation: "off",
                onHoverStop: "on",
                touch: {
                    touchenabled: "on",
                    swipe_threshold: 75,
                    swipe_min_touches: 1,
                    swipe_direction: "horizontal",
                    drag_block_vertical: false
                },
                arrows: {
                    style: "zeus",
                    enable: true,
                    hide_onmobile: true,
                    hide_under: 600,
                    hide_onleave: true,
                    hide_delay: 200,
                    hide_delay_mobile: 1200,
                    tmp: '<div class="tp-title-wrap">    <div class="tp-arr-imgholder"></div> </div>',
                    left: {
                        h_align: "left",
                        v_align: "center",
                        h_offset: 30,
                        v_offset: 0
                    },
                    right: {
                        h_align: "right",
                        v_align: "center",
                        h_offset: 30,
                        v_offset: 0
                    }
                },

                bullets: {
                    enable: false,
                    hide_onmobile: true,
                    hide_under: 600,
                    style: "metis",
                    hide_onleave: true,
                    hide_delay: 200,
                    hide_delay_mobile: 1200,
                    direction: "horizontal",
                    h_align: "center",
                    v_align: "bottom",
                    h_offset: 0,
                    v_offset: 30,
                    space: 5,
                    // tmp: '<span class="tp-bullet-img-wrap">  <span class="tp-bullet-image"></span></span><span class="tp-bullet-title">Some Title</span>'
                }
            },
            responsiveLevels: [1240, 900, 778],
            visibilityLevels: [1240, 900, 778],
            gridwidth: [1903, 900, 778],
            gridheight: [520, 250, 480],
            lazyType: "none",
            parallax: {
                origo: "slidercenter",
                speed: 2000,
                // levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 100, 55],
                type: "scroll"
            },
            shadow: 0,
            spinner: "on",
            stopLoop: "off",
            stopAfterLoops: 0,
            stopAtSlide: 0,
            shuffle: "off",
            autoHeight: "off",
            fullScreenAutoWidth: "off",
            fullScreenAlignForce: "off",
            fullScreenOffsetContainer: "",
            fullScreenOffset: "0",
            hideThumbsOnMobile: "off",
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            debugMode: false,
            fallbacks: {
                simplifyAll: "off",
                nextSlideOnWindowFocus: "off",
                disableFocusListener: false,
            }
        });

    });
    revapi.on('revolution.slide.onbeforeswap', function (event, data) {
        let curEle = data.currentslide[0];
        let nexEle = data.nextslide[0];
        if(curEle && nexEle) {
            let curIndex = curEle.dataset.svindex;
            let nexIndex = nexEle.dataset.svindex;
            let flag = nexIndex>curIndex?"next":"prev"; 
            $node.setSlideIndex(nexIndex);
        }
    });

}

export default constore(function (props) {
    const [services, setServices] = useState(props.getProps('data.services'));
    const [slideIndex, setSlideIndex] = useState(0);
    const service = services[slideIndex];
    const isMobile = document.body.clientWidth <= 900;
    let $node = {
        isMobile,
        props,
        slideIndex,
        services,
        setSlideIndex,
        setServices
    };
    useEffect(() => {
        setupCarosel($node);
    }, [])
    props.$setNode($node);
    return (
        <section id="home" data-name={props.componentName} >
            <div class="container-fluid p-0">
                <div class="rev_slider_wrapper">
                    <div class="rev_slider" data-version="5.0">
                        <ul>
                            {
                                services.map((service, i) => (
                                    <li
                                        data-srvid={service.id}
                                        data-svindex={i}
                                        data-index={"rs-" + (i + 1)}
                                        data-transition="slidingoverlayhorizontal"
                                        data-slotamount="default"
                                        data-easein="default"
                                        data-easeout="default"
                                        data-masterspeed="default"
                                        data-thumb="http://placehold.it/1920x1280"
                                        data-rotate="0"
                                        data-saveperformance="off"
                                        data-title={service.title}
                                        data-description=""
                                        data-bgcolor="#000000"
                                    >
                                        <img
                                            src={"/files/secure/banner_86.png?fileId=" + service.slider_image + "&rd=31&asimg=1" + ("&mobile=" + (isMobile ? '1' : '0'))} alt="home_images"
                                            data-bgposition="center"
                                            data-bgfit="contain"
                                            data-bgcolor="#000000"
                                            data-bgrepeat="no-repeat"
                                            class="rev-slidebg"
                                            data-bgparallax="10"
                                            data-no-retina
                                        />
                                        {/* <img src="image.jpg" 
     srcset="small.jpg 300w,
             medium.jpg 600w,
             large.jpg 900w"
     sizes="(max-width: 300px) 100vw, (max-width: 600px) 50vw, (max-width: 900px) 33vw, 900px"
/> */}
                                        {/* <div
                                            class="tp-caption tp-resizeme text-uppercase text-white font-raleway"
                                            data-y="['bottom']"
                                            data-x="['left']"
                                            data-width="100%"
                                            data-fontsize="['12','12','100']"
                                            data-responsive_offset="on"
                                        >
                                            <div>

                                                <div className="pt-0 text-theme-colored font-20 textAlign-left" style={{ display: 'flex' }} >
                                                    <div style={{ width: '50%', display: 'flex', }} >
                                                        <span style={{ color: 'white', float: 'left' }}>BEFORE</span>

                                                    </div>

                                                    <div>
                                                        <a href={props.buildpath("/" + service.name)} style={{ marginRight: 'auto' }}>
                                                            <span style={{ color: 'rgb(255, 0, 0)' }}>
                                                                {service.title}
                                                                <span style={{ color: 'white', marginLeft: '10px' }}> Starts From&nbsp;${service.price} </span>
                                                            </span>
                                                        </a>
                                                    </div>

                                                    <div style={{ width: '50%', display: 'flex' }}>

                                                        <span style={{ marginLeft: "auto", color: 'white', float: 'right' }}>AFTER</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> */}
                                    </li>
                                ))
                            }

                            {/* <li data-index="rs-1" data-transition="slidingoverlayhorizontal" data-slotamount="default" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb="http://placehold.it/1920x1280" data-rotate="0" data-saveperformance="off" data-title="Slide 1" data-description="">

                                <img src="/files/secure/colorcubz.jpg?fileId=22&rd=41&asimg=1" alt="fdf" data-bgposition="center 10%" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-bgparallax="10" data-no-retina /> */}



                            {/* <div class="tp-caption tp-resizeme text-uppercase text-white font-raleway"
                                    id="rs-1-layer-1"

                                    data-x="['left']"
                                    data-hoffset="['30']"
                                    data-y="['middle']"
                                    data-voffset="['-110']"
                                    data-fontsize="['100']"
                                    data-lineheight="['110']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;s:500"
                                    data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                                    data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                                    data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                                    data-start="1000"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    style={{ zIndex: 7, whiteSpace: 'nowrap', fontWeight: 700 }}>
                                    Education
                                </div> */}

                            {/* 
                                <div class="tp-caption tp-resizeme text-uppercase text-white font-raleway bg-theme-colored-transparent border-left-theme-color-2-6px pl-20 pr-20"
                                    id="rs-1-layer-2"

                                    data-x="['left']"
                                    data-hoffset="['35']"
                                    data-y="['middle']"
                                    data-voffset="['-25']"
                                    data-fontsize="['35']"
                                    data-lineheight="['54']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;s:500"
                                    data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                                    data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                                    data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                                    data-start="1000"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    style={{ zIndex: 7, whiteSpace: 'nowrap', fontWeight: 600 }}>Education For Everyone
                                </div>


                                <div class="tp-caption tp-resizeme text-white"
                                    id="rs-1-layer-3"

                                    data-x="['left']"
                                    data-hoffset="['35']"
                                    data-y="['middle']"
                                    data-voffset="['35']"
                                    data-fontsize="['16']"
                                    data-lineheight="['28']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;s:500"
                                    data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                                    data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                                    data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                                    data-start="1400"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    style={{ zIndex: 5, whiteSpace: 'nowrap', letterSpacing: '0px', fontWeight: 400 }}>
                                    We provides always our best services for our clients and  always<br /> try to achieve our client's trust and satisfaction.
                                </div>


                                <div class="tp-caption tp-resizeme"
                                    id="rs-1-layer-4"

                                    data-x="['left']"
                                    data-hoffset="['35']"
                                    data-y="['middle']"
                                    data-voffset="['100']"
                                    data-width="none"
                                    data-height="none"
                                    data-whitespace="nowrap"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;"
                                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                                    data-start="1400"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    style={{ zIndex: 5, whiteSpace: 'nowrap', letterSpacing: '1px' }}>
                                    <a class="btn btn-colored btn-lg btn-flat btn-theme-colored border-left-theme-color-2-6px pl-20 pr-20" href="#">View Details</a>
                                </div>
                            </li> */}
                        </ul>
                    </div>

                </div>
            </div>
            {
                service && <div className="product_bar" style={{ display: 'flex'}} >
                <div className="before" >
                    <h4 style={{ color: 'white' }} >BEFORE</h4>
                </div>
                <h3 style={{ margin: 'auto' }} >
                    <a href={props.buildpath("/" + service.name)} style={{ color: 'red' }} className=" " >
                        <span>
                            {service.title}
                            <span style={{ color: 'white', marginLeft: '10px' }}>
                                Starts From&nbsp;${service.price}
                            </span>
                        </span>
                    </a>
                </h3>
                <div className="after" >
                    <h4 style={{ color: 'white' }} >AFTER</h4>
                </div>
            </div>
            }
            
        </section>


    )
}, "AppHomeSlider");



